import { subUsersList } from "constants/SettingConstants/subUser";

const initialState = {
  subUsersList: [],
};

const SubUser = (state = initialState, action) => {
  switch (action.type) {
    case subUsersList:
      return { ...state, subUsersList: action.payload };

    default:
      return state;
  }
};

export default SubUser;
