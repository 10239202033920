import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavbarBrand,
} from "reactstrap";
// core components
import { useLocation, useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import AuthFooter from "components/Footers/AuthFooter";
import { verifyUuid } from "action/Auth/publicScreen";
import CommonScreen from "./CommonScreen";
import { SUB_USER_REGISTRATION } from "API/apiConstants";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "API/apiConstants";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [uuidValidation, setUuidValidation] = useState(false);
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const pathSegments = location.pathname.split("/");
  const uuid = pathSegments[pathSegments.length - 1];
  const [res, setRes] = React.useState("");
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  React.useEffect(() => {
    fetchData();
  }, [uuid]);

  const fetchData = async () => {
    try {
      const res = await verifyUuid(uuid);
      if (res.code === 200) {
        setUuidValidation(true);
        setIsLoading(false);
      } else {
        setUuidValidation(false);
        setRes(res);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error verifying UUID:", error);
    }
  };

  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      const strength = getPasswordStrength(value);
      setPasswordStrength(strength);
    }
    setFormData({ ...formData, [name]: value });
    // Remove error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    validateField(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "Strong":
        return "text-success";
      case "Medium":
        return "text-warning";
      case "Weak":
      default:
        return "text-danger";
    }
  };

  const getPasswordStrength = (password) => {
    let strength = "Weak";
    if (
      password.length >= 6 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      strength = "Strong";
    } else if (
      password.length >= 6 &&
      (/[a-z]/.test(password) || /[A-Z]/.test(password))
    ) {
      strength = "Medium";
    }
    return strength;
  };

  const validateField = (name, value) => {
    let error = "";
    const textOnlyRegex = /^[a-zA-Z]+$/;
    switch (name) {
      case "firstName":
        if (!value) {
          error = "First name is required.";
        } else if (value.length < 2) {
          error = "First name must be at least 2 characters long.";
        } else if (value.length > 15) {
          error = "First name must be less than 15 characters.";
        } else if (!textOnlyRegex.test(value)) {
          error = "First name can only contain letters.";
        }
        break;

      case "lastName":
        if (!value) {
          error = "Last name is required.";
        } else if (value.length < 2) {
          error = "Last name must be at least 2 characters long.";
        } else if (value.length > 15) {
          error = "Last name must be less than 15 characters.";
        } else if (!textOnlyRegex.test(value)) {
          error = "Last name can only contain letters.";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required.";
        } else if (value.length < 6) {
          error = "Password must be at least 6 characters long.";
        } else if (value.length > 32) {
          error = "Password must be less than 32 characters.";
        } else if (
          passwordStrength === "Weak" ||
          passwordStrength === "Medium"
        ) {
          error = "Please choose a stronger password.";
        }
        break;

      default:
        if (!value) {
          error = "This field is required.";
        }
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    let isValid = true;
    const fieldsToValidate = ["firstName", "lastName", "password"];

    fieldsToValidate.forEach((field) => {
      validateField(field, formData[field]);
      if (errors[field]) isValid = false;
    });

    return isValid;
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setPasswordVisible(!passwordVisible);
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const hasErrorsOrEmptyFields = () => {
    const isEmpty = Object.values(formData).some(
      (field) => field.trim() === ""
    );
    const hasErrors = Object.values(errors).some((error) => error !== "");
    return (
      isEmpty || hasErrors || !recaptchaValue || passwordStrength !== "Strong"
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      fetch(`${SUB_USER_REGISTRATION}${uuid}`, {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("p", JSON.stringify(res.data));
            navigate("/a/dashboard");
          } else {
            if (Array.isArray(res.message)) notify("error", res.message[0].Msg);
            else notify("error", res.message);
          }
        });
    } else {
      notify("error", { message: "Please fill correct data." });
    }
  };

  const styles = {
    footer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "25vh",
    },
    spinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full viewport height
      width: "100vw", // Full viewport width
      backgroundColor: "white", // Blank screen background color
    },
    terms: { fontSize: "12px" },
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      position: "absolute",
      top: "15px",
    },
    image: {
      width: windowWidth <= 576 ? "40px" : windowWidth <= 768 ? "50px" : "65px",
      height:
        windowWidth <= 576 ? "35px" : windowWidth <= 768 ? "45px" : "59px",
    },
    text: {
      fontFamily: "rubik",
      color: "white",
      fontSize:
        windowWidth <= 576 ? "24px" : windowWidth <= 768 ? "28px" : "36px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "5px", // Spacing between image and text
      marginBottom: 0, // Ensure no extra margin below the text
    },
  };

  return (
    <>
      {isLoading ? (
        <div style={styles.spinner}>
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      ) : (
        <>
          {uuidValidation ? (
            <div className="bg-default">
              <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
              </div>
              <div
                className="header py-8"
                style={{ backgroundColor: "#0da5c0" }}
              >
                <Container>
                  <NavbarBrand>
                    <div
                      style={styles.container}
                      className="align-items-center d-flex mt-2"
                    >
                      <img
                        alt="..."
                        className="navbar-brand-img align-items-center d-flex"
                        src={require("assets/img/brand/bulk-logo.png")}
                        style={styles.image}
                      />
                      <p style={styles.text}>BulkCalendar</p>
                    </div>
                  </NavbarBrand>
                </Container>
                <Container>
                  <div className="header-body text-center mt--3">
                    <Row className="justify-content-center">
                      <Col className="px-5" lg="6" md="8" xl="5">
                        <h1 className="text-white">Welcome!</h1>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 256 100"
                    x="0"
                    y="0"
                  >
                    <polygon
                      className="fill-default"
                      points="2560 0 256 100 0 100"
                    />
                  </svg>
                </div>
              </div>
              <Container className="mt--7 pb-5 mb--6">
                <Row className="justify-content-center">
                  <Col lg="6" md="8">
                    <Card className="bg-secondary border-0 justify-content-center">
                      <CardHeader className="bg-transparent pb-2 text-center">
                        Add Account Registration Details
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-4">
                        <Form role="form" onSubmit={handleSubmit}>
                          <Row>
                            <Col md="12" className="mb-3">
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-circle-08" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  name="firstName"
                                  placeholder="First Name"
                                  type="text"
                                  value={formData.firstName}
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.firstName ? "is-invalid" : ""
                                  }
                                />
                              </InputGroup>
                              {errors.firstName && (
                                <small className="text-danger">
                                  {errors.firstName}
                                </small>
                              )}
                            </Col>
                            <Col md="12" className="mb-3">
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-circle-08" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  name="lastName"
                                  placeholder="Last Name"
                                  type="text"
                                  value={formData.lastName}
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.lastName ? "is-invalid" : ""
                                  }
                                />
                              </InputGroup>
                              {errors.lastName && (
                                <small className="text-danger">
                                  {errors.lastName}
                                </small>
                              )}
                            </Col>
                            <Col md="12">
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fa fa-lock" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  name="password"
                                  placeholder="Password"
                                  type={passwordVisible ? "text" : "password"}
                                  value={formData.password}
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.password ? "is-invalid" : ""
                                  }
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i
                                      className={`fa ${
                                        passwordVisible
                                          ? "fa-eye-slash"
                                          : "fa-eye"
                                      }`}
                                      aria-hidden="true"
                                      onClick={() =>
                                        togglePasswordVisibility("password")
                                      }
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                              {errors.password && (
                                <small className="text-danger">
                                  {errors.password}
                                </small>
                              )}
                              <div className="text-muted">
                                <small>
                                  Password strength:{" "}
                                  <span className={getPasswordStrengthColor()}>
                                    {passwordStrength}
                                  </span>
                                </small>
                              </div>
                            </Col>
                            <ReCAPTCHA
                              sitekey={SITE_KEY}
                              onChange={onRecaptchaChange}
                              className="ml-3 mt-2"
                            />
                          </Row>
                          <Row>
                            <div className="ml-3 mt-2 " style={styles.terms}>
                              <label>
                                <span>
                                  {" "}
                                  By clicking the "Create Account" button, you
                                  agree to our{" "}
                                  <a
                                    className="text-blue ml-1"
                                    href="https://www.bulkcalendar.com/terms-of-service/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Terms of Service
                                  </a>{" "}
                                  and
                                </span>
                                <a
                                  className="text-blue ml-1"
                                  href="https://www.bulkcalendar.com/privacy-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                              </label>
                            </div>
                          </Row>
                          <div className="text-center">
                            <Button
                              className="mt-4"
                              color="info"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={hasErrorsOrEmptyFields()}
                            >
                              Create account
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div style={styles.footer}>
                <div style={{ flex: 1 }}></div>
                <AuthFooter />
              </div>
            </div>
          ) : (
            <CommonScreen
              res={res}
              title={"Error"}
              onClick
              description={
                "This link has expired. Please contact your administrator for assistance."
              }
              button={"BulkCalendar.com"}
              color="red"
            />
          )}
        </>
      )}
    </>
  );
}

export default Register;
