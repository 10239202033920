/* eslint-disable no-undef */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  Label,
  ButtonGroup,
} from "reactstrap";
import { CardActions, Divider } from "@mui/material";
import { storePlanData } from "action/Billing/plans";
import { useDispatch, useSelector } from "react-redux";
import { planList } from "action/Billing/plans";
import { checkPaymentStatus } from "action/Billing/plans";
import CancelPlanModal from "./CancelModal";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";
import { createOrder } from "action/Billing/plans";
import { renewalRequired } from "action/Billing/plans";
import PaypalButton from "./Paypal";

// const topUpPlans = [
//   {
//     planID: 1,
//     value: "$ 5",
//     credits: "400 credits",
//     validity: "28 days",
//     totalCredits: "7400",
//   },
//   {
//     planID: 2,
//     value: "$ 9",
//     credits: "1500 credits",
//     validity: "28 days",
//     totalCredits: "8500",
//   },
//   {
//     planID: 3,
//     value: "$ 19",
//     credits: "2000 credits",
//     validity: "56 days",
//     totalCredits: "9000",
//   },
//   {
//     planID: 4,
//     value: "$ 29",
//     credits: "3000 credits",
//     validity: "84 days",
//     totalCredits: "10,000",
//   },
//   {
//     planID: 5,
//     value: "$ 49",
//     credits: "10000 credits",
//     validity: "84 days",
//     totalCredits: "17000",
//   },
// ];
function Plans() {
  // Define state to manage hover
  const dispatch = useDispatch();
  const planData = useSelector((state) => state.Plans.planListData);
  const renewal = useSelector((state) => state.Plans.renewalRequiredData);
  const [hoveredCard, setHoveredCard] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activeCurrency, setActiveCurrency] = React.useState("");
  const [topUp, setTopUp] = React.useState(false);
  const [paypalPlanID, setPaypalPlanID] = React.useState(0);
  const [paypalModal, setPaypalModal] = React.useState(false);
  const [rotated, setRotated] = React.useState(false);
  const [openDowngrade, setOpenDowngrade] = React.useState(false);
  const [changePlanModal, setChangePlanModal] = React.useState(false);
  const [planID, setPlanID] = React.useState(0);
  const notificationAlertRef = React.useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const email = JSON.parse(localStorage.getItem("p"))?.Email;

  React.useEffect(() => {
    planList(dispatch);
    renewalRequired(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    if (planData) {
      setActiveCurrency(
        planData?.tenantActivePlan?.subScribeToGateway === "RAZORPAY" &&
          planData?.tenantActivePlan?.planID !== 1
          ? "RUPEES"
          : "DOLLAR"
      );
    }
  }, [planData]);

  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };
  // Function to handle hover
  const handleMouseEnter = (cardName) => {
    setHoveredCard(cardName);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  // Define the inline style for hover
  const getCardStyle = (cardName, length) => ({
    display: "flex",
    width:
      length >= 4 ? "100%" : length >= 3 ? "80%" : length >= 2 ? "60%" : "30% ",
    alignItems: "center",

    justifyContent: "center",
    backgroundColor: hoveredCard === cardName ? "#11cdef" : "transparent",
    color: hoveredCard === cardName ? "white" : "black",
    transition: "background-color 0.3s ease, color 0.3s ease",
    textAlign: "center",
  });

  // Define the inline style for button
  const getButtonStyle = (cardName) => ({
    backgroundColor: hoveredCard === cardName ? "#fb6340" : "#11cdef",
  });

  const createUserOrder = async (ID, planID) => {
    if (activeCurrency === "DOLLAR") {
      setPaypalModal(true);
      setPlanID(ID);
      setPaypalPlanID(planID);
    } else {
      setLoading(true);
      try {
        // Assuming `createPlan` is an async function that fetches the subscription plan details.
        const res = await createOrder({ plan_id: ID });
        const data = res.data;
        if (res.code === 200) {
          setLoading(false);
          const options = {
            key: data.APIKey, // Razorpay API Key
            currency: data.Currency,
            order_id: data.OrderID,
            name: data.CompanyName,
            description: data.Description,
            handler: async function (response) {
              // Payment response handler
              setLoading(true);
              const paymentData = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                order_id: data.OrderID,
                plan_id: ID,
              };
              const storePlanRes = await storePlanData(paymentData);
              if (storePlanRes.code === 200) {
                setLoading(true);
                paymentStatus(response.razorpay_payment_id);
                let callCount = 0;
                const delayedCall = () => {
                  callCount++;
                  if (callCount <= 2) {
                    setTimeout(delayedCall, 5000);
                  } else if (callCount <= 5) {
                    setTimeout(delayedCall, 7000);
                  } else if (callCount <= 10) {
                    setTimeout(delayedCall, 10000);
                  }
                };
                setTimeout(delayedCall, 2000);
              } else {
                setLoading(false);
                notify("error", "Failed to store payment data");
              }
            },
            // prefill: {
            //   name: "John Doe", // Prefill user's name
            //   email: "john@example.com", // Prefill user's email
            //   contact: "9999999999", // Prefill user's contact number
            // },
            // theme: {
            //   color: "#3399CC", // Theme color of the Razorpay checkout
            // },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          setLoading(false);
          notify("error", "Error in subscribing user");
        }
      } catch (error) {
        setLoading(false);
        notify("error", "Error in subscribing user");
      }
    }
    // }
  };
  const downgradePlan = async (ID) => {
    const res = await createOrder({ plan_id: ID });
    if ((res.code = 200)) {
      setOpenDowngrade(false);
      planList(dispatch);
      renewalRequired(dispatch);
      Swal.fire({
        position: "middle",
        icon: "success",
        title: `Plan Downgrade successful`,
        text: `Your next plan will be activated on ${planData.tenantActivePlan?.planRenewalDate}`,
        showConfirmButton: true,
        confirmButtonColor: "#0DCAF0",
      });
    } else {
    }
  };

  const paymentStatus = async (ID) => {
    const res = await checkPaymentStatus(ID);
    setLoading(false);
    if (res.data === "authorized" || res.data === "created") {
      paymentStatus(ID);
    } else {
      setLoading(false);
      planList(dispatch);
      renewalRequired(dispatch);
      setOpenDowngrade(false);
      notify("success", res.message);
      return;
    }
  };

  const handleClick = () => {
    setRotated(!rotated);
  };
  const handleModal = (planID) => {
    setOpenDowngrade(true);
    setPlanID(planID);
  };
  // const handleTopUp = () => {
  //   setTopUp(!topUp);
  // };
  const changePlan = () => {
    setChangePlanModal(!changePlanModal);
  };

  const getDays = (date) => {
    const currentDate = new Date(); // Get the current date
    const future = new Date(date); // Convert the input to a Date object

    if (isNaN(future.getTime())) {
      return; // Check for invalid date input
    }

    // Calculate the difference in milliseconds
    const diffInMs = future - currentDate;

    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return diffInDays;
  };

  const handleActive = (value) => {
    setActiveCurrency(value);
  };

  const closeModal = () => {
    setPaypalModal(false);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {loading && (
        <div style={styles.backdrop}>
          <i
            className="fa fa-spinner fa-spin text-white"
            style={styles.spinner}
          />
        </div>
      )}
      {/* <SimpleHeader name="Company Name" /> */}

      <Container className="mt--6" fluid>
        <Modal
          isOpen={paypalModal}
          // toggle={setPaypalModal(false)}
          centered={true}
          contentClassName={""}
        >
          <ModalHeader toggle={() => setPaypalModal(false)}>
            Paypal Payments
          </ModalHeader>
          <ModalBody>
            <PaypalButton
              closeModal={closeModal}
              paypalPlanID={paypalPlanID}
              planID={planID}
              setLoading={setLoading}
              notify={notify}
              clientID={planData.clientID}
            />
          </ModalBody>
        </Modal>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="d-flex align-items-center">
                Current Plan Summary
                <i className="fa fa-check-circle ml-2 " style={styles.icon}></i>
              </h3>

              {/* <Button color="info" outline onClick={handleTopUp}>
              Add More Credit
              </Button> */}
              {planData.tenantActivePlan?.planID === 1 ? null : (
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <Media className="ml-2 d-flex d-lg-block align-items-center">
                        <span
                          className="btn-inner--icon ml-2 mt--1"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => changePlan()}>
                      <span>Cancel Subscription</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
            {planData.downGradePlan?.ID &&
            planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} plan will
                end on {planData.tenantActivePlan?.planRenewalDate} and you will
                be moved to the new {planData.downGradePlan?.name} plan.
              </h3>
            ) : renewal?.renewalRequired &&
              planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} Plan will
                expire in {getDays(planData.tenantActivePlan?.planRenewalDate)}{" "}
                days, Renew now to continue enjoying all your benefits without
                interruption!
              </h3>
            ) : planData?.isPlanCancelled &&
              planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} plan will be
                converted to Free Plan in
                {getDays(planData.tenantActivePlan?.planRenewalDate)} days.
              </h3>
            ) : null}
          </CardHeader>
          <CardBody>
            {planData.tenantActivePlan ? (
              <>
                <Row md={12}>
                  <Col md={4}>
                    <Label>Plan Name</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planName}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Billing Cycle</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planType === "STANDARD"
                        ? "Monthly"
                        : "Custom"}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Plan Cost</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planUsdRate
                        ? activeCurrency === "DOLLAR"
                          ? `$${planData.tenantActivePlan?.planUsdRate}`
                          : `₹${planData.tenantActivePlan?.planInrRate}`
                        : "Free"}{" "}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>Allocated Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.allocatedCredits}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Used Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.UsedCredits}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Remaining Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.availableCredits}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>API support</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.apiAllowed}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Last Renewed on </Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planStartDate}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Next Renewal on </Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planRenewalDate}
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <div style={styles.loaderContainer}>Something went wrong</div>
            )}
          </CardBody>
          {planData.tenantActivePlan?.planID !== 1 ? (
            <CardFooter className="d-flex justify-content-end">
              {renewal?.renewalRequired ? (
                <Button
                  color="info"
                  onClick={() =>
                    createUserOrder(planData.tenantActivePlan?.planID)
                  }
                >
                  Renew
                </Button>
              ) : null}
            </CardFooter>
          ) : null}
        </Card>

        {renewal?.dongradeRequired ? (
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="d-flex align-items-center">
                  Downgraded Plan Summary
                  {/* <i className="fa fa-check-circle ml-2 " style={styles.icon}></i> */}
                </h3>

                {/* <Button color="info" outline onClick={handleTopUp}>
              Add More Credit
              </Button> */}
                {/* {planData.tenantActivePlan?.planID === 1 ? null : (
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <Media className="ml-2 d-flex d-lg-block align-items-center">
                        <span
                          className="btn-inner--icon ml-2 mt--1"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => changePlan()}>
                      <span>Cancel Subscription</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )} */}
              </div>
              <h3 style={styles.noteTextStyle}>
                Notice : Please Pay for your {planData.downGradePlan?.name}{" "}
                otherwise the plan will converted into Free plan in{" "}
                {getDays(planData.tenantActivePlan?.planRenewalDate)} days
              </h3>
            </CardHeader>
            <CardBody>
              <Row md={12}>
                <Col md={4}>
                  <Label>Plan Name</Label>
                  <p className="mt--2">{planData.downGradePlan?.name}</p>
                </Col>
                <Col md={4}>
                  <Label>Billing Cycle</Label>
                  <p className="mt--2">
                    {planData.downGradePlan?.type === "STANDARD"
                      ? "Monthly"
                      : "Custom"}
                  </p>
                </Col>
                <Col md={4}>
                  <Label>Plan Cost</Label>
                  <p className="mt--2">
                    {planData.tenantActivePlan?.planUsdRate
                      ? activeCurrency === "DOLLAR"
                        ? `$${planData.tenantActivePlan?.planUsdRate}`
                        : `₹${planData.tenantActivePlan?.planInrRate}`
                      : "Free"}{" "}
                  </p>
                </Col>
              </Row>
              <Row md={12} className="mt-4">
                <Col md={4}>
                  <Label>Allocated Credits</Label>
                  <p className="mt--2">{planData.downGradePlan?.credits}</p>
                </Col>
                {/* <Col md={4}>
                <Label>Used Credits</Label>
                <p className="mt--2">
                  {planData.downGradePlan?.UsedCredits}
                </p>
              </Col> */}
                {/* <Col md={4}>
                <Label>Remaining Credits</Label>
                <p className="mt--2">
                  {planData.downGradePlan?.availableCredits}
                </p>
              </Col> */}
                {/* </Row>
            <Row md={12} className="mt-4"> */}
                <Col md={4}>
                  <Label>API support</Label>
                  <p className="mt--2">
                    {planData.downGradePlan?.apiAllowed
                      ? planData.downGradePlan?.apiAllowed
                      : "NO"}{" "}
                  </p>
                </Col>
                {/* <Col md={4}>
                <Label>Last Renewed on </Label>
                <p className="mt--2">{planData.downGradePlan?.planStartDate}</p>
              </Col>
              <Col md={4}>
                <Label>Next Renewal on </Label>
                <p className="mt--2">
                  {planData.downGradePlan?.planID !== 1
                    ? planData.downGradePlan?.planRenewalDate
                    : "NA"}
                </p>
              </Col> */}
              </Row>
            </CardBody>

            <CardFooter className="d-flex justify-content-end">
              <Button
                color="info"
                onClick={() => createUserOrder(planData.downGradePlan?.ID)}
              >
                Pay Now
              </Button>
            </CardFooter>
          </Card>
        ) : null}
        <Card className="mb-4">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="d-flex align-items-center">Plans</h3>
              {planData.tenantActivePlan?.planID === 1 && (
                <ButtonGroup>
                  <Button
                    className={
                      activeCurrency === "DOLLAR" ? "bg-warning text-white" : ""
                    }
                    color="secondary"
                    type="button"
                    onClick={() => handleActive("DOLLAR")}
                  >
                    $ USD
                  </Button>
                  <Button
                    className={
                      activeCurrency === "RUPEES" ? "bg-warning text-white" : ""
                    }
                    color="secondary"
                    type="button"
                    onClick={() => handleActive("RUPEES")}
                  >
                    ₹ INR
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <Row md={12}>
              {planData.plansAfterActivePlan?.map((plan) => (
                <Col
                  md={
                    planData.plansAfterActivePlan?.length > 4
                      ? 3
                      : 12 / (planData.plansAfterActivePlan?.length - 1)
                  }
                  lg={
                    planData.plansAfterActivePlan?.length > 4
                      ? 3
                      : 12 / (planData.plansAfterActivePlan?.length - 1)
                  }
                  sm={12}
                  xs={12}
                  key={plan.name}
                  className="d-flex justify-content-center"
                >
                  {plan.name === "Testing plan" && plan.usdRate === 1 ? null : (
                    <>
                      <Card
                        md={4}
                        lg={3}
                        style={getCardStyle(
                          plan.name,
                          planData.plansAfterActivePlan.length <= 4
                            ? planData.plansAfterActivePlan.length - 1
                            : planData.plansAfterActivePlan.length
                        )}
                        onMouseEnter={() => handleMouseEnter(plan.name)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {plan.popular === "YES" && (
                          <div style={styles.popularWord}>
                            <span>P</span>
                            <span>O</span>
                            <span>P</span>
                            <span>U</span>
                            <span>L</span>
                            <span>A</span>
                            <span>R</span>
                          </div>
                        )}

                        <div className="mt-3">
                          {plan.name}
                          <Divider className="mt-3" />
                        </div>
                        <CardBody className="p-2">
                          <h1
                            style={{
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            {activeCurrency === "RUPEES" ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"
                                  style={styles.currencyIcon}
                                />
                                {plan.inrRate}
                              </>
                            ) : (
                              <>${plan.usdRate}</>
                            )}
                            <p>Per Month</p>
                          </h1>
                          <Divider className="mb-4" />
                          <p
                            color="secondary"
                            className="fs-5 d-flex justify-content-left align-items-center "
                            style={{
                              // fontWeight: hoveredCard === plan.name ? "bold" : "",
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            <Col>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.credits} Credits
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.creditsCarryForward} Credits carry forward
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.campaignsLimit}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.subUsersLimit}
                              </Row>

                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature1}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature2}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {activeCurrency === "RUPEES"
                                  ? plan.ratePerInviteInr
                                  : plan.ratePerInvite}{" "}
                                {activeCurrency === "RUPEES"
                                  ? "INR/Credit"
                                  : "USD/Credit"}
                              </Row>
                            </Col>
                          </p>
                        </CardBody>
                        <CardActions>
                          <Button
                            color="info"
                            className="mb-3 mt--3"
                            style={getButtonStyle(plan.name)}
                            onClick={() =>
                              createUserOrder(plan.ID, plan.paypalPlanID)
                            }
                          >
                            Upgrade
                          </Button>
                        </CardActions>
                      </Card>
                    </>
                  )}
                </Col>
              ))}
            </Row>

            {email === "priyanka.c@scalent.io" ||
            email === "pankaj.khairnar@gmail.com" ? (
              <>
                {planData.plansAfterActivePlan?.map((plan) => (
                  <Col
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    key={plan.name}
                    className="d-flex justify-content-start"
                  >
                    {plan.name === "Testing plan" && plan.usdRate === 1 ? (
                      <Card
                        md={3}
                        lg={3}
                        style={getCardStyle(plan.name, 4)}
                        onMouseEnter={() => handleMouseEnter(plan.name)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div className="mt-3">
                          {plan.name}
                          <Divider className="mt-3" />
                        </div>
                        <CardBody className="p-2">
                          <h1
                            style={{
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            {activeCurrency === "RUPEES" ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"
                                  style={styles.currencyIcon}
                                />
                                {plan.inrRate}
                              </>
                            ) : (
                              <>${plan.usdRate}</>
                            )}
                            <p>Per Month</p>
                          </h1>
                          <Divider className="mb-4" />
                          <p
                            color="secondary"
                            className="fs-5 d-flex justify-content-left align-items-center "
                            style={{
                              // fontWeight: hoveredCard === plan.name ? "bold" : "",
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            <Col>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.credits} Credits
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.creditsCarryForward} Credits carry forward
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.campaignsLimit}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.subUsersLimit}
                              </Row>

                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature1}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature2}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {activeCurrency === "RUPEES"
                                  ? plan.ratePerInviteInr
                                  : plan.ratePerInvite}{" "}
                                {activeCurrency === "RUPEES"
                                  ? "INR/Credit"
                                  : "USD/Credit"}
                              </Row>
                            </Col>
                          </p>
                        </CardBody>
                        <CardActions>
                          <Button
                            color="info"
                            className="mb-3 mt--3"
                            style={getButtonStyle(plan.name)}
                            onClick={() =>
                              createUserOrder(plan.ID, plan.paypalPlanID)
                            }
                          >
                            Upgrade
                          </Button>
                        </CardActions>
                      </Card>
                    ) : null}
                  </Col>
                ))}
              </>
            ) : null}

            <Row md={12} className="border solid 1px rounded">
              <Col md={6} className="p-6">
                <h3> What's Included</h3>
                <div color="secondary" className="fs-5 mt-3">
                  <Row>
                    <Col>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Full control
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Full customization
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Unlimited credits
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Unlimited campaign
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        On call support
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Customization option
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5} className="mt-5 mb-3 d-flex justify-content-center">
                <div
                  className="border d-flex flex-column justify-content-center align-items-center "
                  style={styles.boxStyle}
                >
                  <h1 style={styles.h1} className="text-center">
                    Self Hosted
                  </h1>
                  <h3 style={styles.h2} className="text-center">
                    Deploy on your Owned Server
                  </h3>
                  <h2 style={styles.h2} className="text-center">
                    Contact sales on&nbsp;
                    <a
                      href="mailto:sales@bulkcalendar.co"
                      style={{
                        textDecoration: isHovered ? "underline" : "none",
                        color: "inherit",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      sales@bulkcalendar.co
                    </a>
                  </h2>
                </div>
              </Col>
            </Row>
            <Row md={12} className="d-flex justify-content-end mr-2 mt-3">
              {planData?.plansBeforeActivePlan?.length === 0 ? null : (
                <h4
                  style={{ color: "#11cdef", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  <br />
                  More Plans
                  <i
                    className={`fa fa-caret-right ml-1`}
                    style={
                      rotated ? styles.rotateIcon : styles.rotateIconReverse
                    }
                  ></i>
                </h4>
              )}
            </Row>
            {rotated ? (
              <Row md={12}>
                {planData.plansBeforeActivePlan?.map((plan) => (
                  <Col
                    md={12 % planData.plansBeforeActivePlan.length}
                    key={plan.name}
                    className="d-flex justify-content-center"
                  >
                    <Card
                      md={3}
                      style={getCardStyle(
                        plan.name,
                        planData.plansBeforeActivePlan.length
                      )}
                      onMouseEnter={() => handleMouseEnter(plan.name)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {plan.popular === "YES" && (
                        <div style={styles.popularWord}>
                          <span>P</span>
                          <span>O</span>
                          <span>P</span>
                          <span>U</span>
                          <span>L</span>
                          <span>A</span>
                          <span>R</span>
                        </div>
                      )}

                      <div className="mt-3">
                        {plan.name}
                        <Divider className="mt-3" />
                      </div>
                      <CardBody>
                        <h1
                          style={{
                            color:
                              hoveredCard === plan.name ? "white" : "black",
                          }}
                        >
                          {activeCurrency === "RUPEES" ? (
                            <>
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={styles.currencyIcon}
                              />
                              {plan.inrRate}
                            </>
                          ) : (
                            <>${plan.usdRate}</>
                          )}
                          <p>Per Month</p>
                        </h1>
                        <Divider className="mb-4" />

                        <p
                          color="secondary"
                          className="fs-5 d-flex justify-content-left align-items-center"
                          style={{
                            // fontWeight: hoveredCard === plan.name ? "bold" : "",
                            color:
                              hoveredCard === plan.name ? "white" : "black",
                          }}
                        >
                          <Col>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.credits} Credits
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.creditsCarryForward} Credits carry forward
                            </Row>

                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.campaignsLimit}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.subUsersLimit}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.feature1}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.feature2}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {activeCurrency === "RUPEES"
                                ? plan.ratePerInviteInr
                                : plan.ratePerInvite}{" "}
                              {activeCurrency === "RUPEES"
                                ? "INR/Credit"
                                : "USD/Credit"}
                            </Row>
                          </Col>
                        </p>
                      </CardBody>
                      <CardActions>
                        <Button
                          color="info"
                          className="m-3 mt--4"
                          style={getButtonStyle(plan.name)}
                          onClick={() => handleModal(plan.ID)}
                        >
                          Downgrade
                        </Button>
                      </CardActions>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : null}
          </CardBody>
          <CancelPlanModal
            changePlanModal={changePlanModal}
            changePlan={changePlan}
          />
          {/* <Modal
            isOpen={topUp}
            toggle={handleTopUp}
            className="modal-dialog-centered modal-secondary"
          >
            <ModalHeader>Top Up Plans</ModalHeader>
            <ModalBody>
              <div className="plan-list">
                {topUpPlans.map((plan) => (
                  <Card
                    key={plan.planID}
                    className={`mb-3 ${
                      selectedPlan === plan.planID ? "border-primary" : ""
                    }`}
                    onClick={() => setSelectedPlan(plan.planID)}
                  >
                    <CardBody>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-between align-items-center">
                            <CardTitle tag="h2" className="mb-0">
                              {plan.value}
                            </CardTitle>
                            <a className="text-blue" href="#">
                              View Details
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <CardText>
                        <Row>
                          <Col>
                            <strong>Credits:</strong>
                          </Col>
                          <Col>
                            <strong>Validity:</strong>
                          </Col>
                          <Col>
                            <strong>Total Credits:</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col>{plan.credits}</Col>
                          <Col>{plan.validity}</Col>
                          <Col>{plan.totalCredits}</Col>
                        </Row>
                      </CardText>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </ModalBody>
          </Modal> */}
          <Modal
            isOpen={openDowngrade}
            toggle={handleModal}
            className="modal-dialog-centered modal-secondary"
          >
            <ModalHeader>Downgrade plan</ModalHeader>
            <ModalBody>
              <div className="plan-list">
                Please note that this plan will be activated once your current
                plan expires. You'll continue to enjoy the benefits of your
                existing plan until the end of its term, after which the new
                plan will seamlessly take effect, ensuring uninterrupted
                service.
              </div>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => setOpenDowngrade(false)}
                >
                  Back
                </Button>
                <Button color="info" onClick={() => downgradePlan(planID)}>
                  It's Fine
                </Button>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </Card>
      </Container>
    </>
  );
}

export default Plans;
const styles = {
  boxStyle: {
    backgroundColor: "#11cdef",
    borderRadius: "1rem",
    height: "85%",
    width: "80%",
    padding: "40px",
  },
  h1: {
    fontSize: "50px",
    color: "white",
  },
  h2: {
    fontSize: "18px",
    color: "white",
  },
  popularWord: {
    position: "absolute",
    marginRight: "20px",
    top: "0px",
    right: "0",
    backgroundColor: "#FF6F61",
    color: "white",
    padding: "0px 8px",
    fontWeight: "bold",
    fontSize: "12px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rotateIcon: {
    transition: "transform 0.3s ease",
    transform: "rotate(90deg)",
  },
  rotateIconReverse: {
    transition: "transform 0.3s ease",
    transform: "rotate(-90deg)",
  },

  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
    zIndex: 9999, // High z-index to cover the whole content
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centers the spinner
  },
  spinner: {
    fontSize: "2.5rem", // Adjust size if needed
    color: "white",
  },
  noteTextStyle: {
    marginBottom: "-15px",
    fontWeight: "normal",
    width: "100%",
    color: "red",
    fontSize: "0.875rem",
    // color: "#6c757d",
  },
  icon: {
    color: "green",
  },
  currencyIcon: { fontSize: "1.3rem" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
};
