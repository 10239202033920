import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

const EmailEditor = ({
  createCampaignFields,
  handleOnchange,
  handleOnBlur,
}) => {
  // Helper function to check and ensure the unsubscribe tag is present
  const ensureUnsubscribeTag = (content) => {
    const unsubscribeTag = "{unsubscribe-link}";
    if (!content.includes(unsubscribeTag)) {
      // If unsubscribe tag is not present, append it
      return `${content}<br><br><br><br><span style="color: gray;">${unsubscribeTag}</span>`;
    }
    return content;
  };

  // State for managing the email body
  const [emailBody, setEmailBody] = useState(
    createCampaignFields.emailBody.value
  );

  // Sync the external state when it changes
  useEffect(() => {
    setEmailBody(createCampaignFields.emailBody.value);
  }, [createCampaignFields.emailBody.value]);

  // Handler for content change in the editor
  const handleEditorChange = (value) => {
    setEmailBody(value);
    handleOnchange("emailBody", value); // call the external onchange handler
  };

  // Handler for blur event (when editor loses focus)
  const handleEditorBlur = () => {
    setEmailBody(emailBody);
    handleOnBlur(true, "emailBody", emailBody);
  };

  return (
    <div>
      <ReactQuill
        style={{ height: "20rem" }}
        theme="snow"
        placeholder="Email Body"
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "link"], // toggled buttons
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ font: [] }],
          ],
        }}
        value={emailBody}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
        maxLength={5000}
      />
      {!createCampaignFields.emailBody.isValid && (
        <div className="mt-5" style={{ color: "red", fontSize: 12 }}>
          {createCampaignFields.emailBody.errMsg}
        </div>
      )}
    </div>
  );
};

export default EmailEditor;
