import { profileDetails } from "constants/SettingConstants/profile";
import * as api from "../../API/index";
import { timeZone } from "constants/SettingConstants/profile";
import { authorizedUser } from "components/CommonFunctions";

export const getProfileDetail = async (dispatch) => {
  try {
    const res = await api.get_profile_details();
    dispatch({ type: profileDetails, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    console.log(error);
  }
};

export const getTimeZoneList = async (dispatch) => {
  try {
    const res = await api.get_time_zone();
    dispatch({ type: timeZone, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    console.log(error);
  }
};

export const updateProfile = async (data) => {
  try {
    const res = await api.update_profile(data);
    return res.data;
    // dispatch({ type: profileDetails, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    return error?.response?.data;
  }
};

export const resendVerifyEmail = async () => {
  try {
    const res = await api.resend_verify_email();
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error?.response?.data;
  }
};

export const userLogout = async () => {
  try {
    const res = await api.user_logout();
    return res.data;
  } catch (error) {
    return error?.response?.data;
  }
};
