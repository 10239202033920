import {
  attendeeList,
  campaignDetails,
  appliedFiltersOnAttendees,
} from "constants/CampaignConstants/createCampaign";

const initialState = {
  attendeeList: {},
  campaignDetails: {},
  appliedFiltersOnAttendees: {
    pageNum: 1,
    filterParams: "",
    sortParam: "",
  },
};

const createCampaign = (state = initialState, action) => {
  switch (action.type) {
    case attendeeList:
      return { ...state, attendeeList: action.payload };

    case campaignDetails:
      return { ...state, campaignDetails: action.payload };

    case appliedFiltersOnAttendees:
      return { ...state, appliedFiltersOnAttendees: action.payload };

    default:
      return state;
  }
};

export default createCampaign;
