import {
  planListData,
  paymentHistoryList,
  renewalRequiredData,
} from "constants/Billing/planConstant";
import * as api from "../../API/index";
import { authorizedUser } from "components/CommonFunctions";

export const createOrder = async (ID) => {
  try {
    const res = await api.create_order(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const storePlanData = async (planData) => {
  try {
    const res = await api.store_plan_data(planData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const planList = async (dispatch) => {
  try {
    const res = await api.plan_list();
    dispatch({ type: planListData, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const checkPaymentStatus = async (ID) => {
  try {
    const res = await api.check_payment_status(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const cancelPlan = async (data) => {
  try {
    const res = await api.cancel_plan(data);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const paymentHistory = async (dispatch, pageNum) => {
  try {
    const res = await api.payment_history(pageNum);
    dispatch({ type: paymentHistoryList, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: paymentHistoryList, payload: error.response.data });
  }
};

export const renewalRequired = async (dispatch) => {
  try {
    const res = await api.renewal_required();
    dispatch({ type: renewalRequiredData, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: renewalRequiredData, payload: error.response.data });
  }
};

export const downloadInvoice = async (invoiceID) => {
  try {
    const res = await api.download_invoice(invoiceID);
    return res;
  } catch (error) {
    authorizedUser(error.response.data);
    return error;
  }
};

export const paypalPlanSubscription = async (planData) => {
  try {
    const res = await api.paypal_plan_subscription(planData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
