import {
  planListData,
  paymentHistoryList,
  renewalRequiredData,
} from "constants/Billing/planConstant";

const initialState = {
  planListData: {},
  paymentHistoryList: {},
  renewalRequiredData: {},
};

const Plans = (state = initialState, action) => {
  switch (action.type) {
    case planListData:
      return { ...state, planListData: action.payload };

    case paymentHistoryList:
      return { ...state, paymentHistoryList: action.payload };

    case renewalRequiredData:
      return { ...state, renewalRequiredData: action.payload };

    default:
      return state;
  }
};

export default Plans;
