/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  NavItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { userLogout } from "action/Settings/profile";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const navigate = useNavigate();
  const userName = JSON.parse(localStorage.getItem("p"));

  const logout = async () => {
    await userLogout();
    localStorage.removeItem("p");
    navigate("/auth/login");
  };
  const organizationName = JSON.parse(localStorage.getItem("p"));
  const capitalized = organizationName?.organizationName
    ? organizationName?.organizationName.charAt(0).toUpperCase() +
      organizationName?.organizationName.slice(1)
    : "";
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info pb-7 pt-4": theme === "dark" },
          { "navbar-light bg-secondary pb-7 pt-4": theme === "light" }
        )}
      >
        <Container fluid>
          <span className="text-white h3 d-none d-sm-block ">
            {capitalized}
          </span>
          <Collapse navbar isOpen={true} style={{ justifyContent: "flex-end" }}>
            <Nav className="align-items-center ml-md-auto " navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler mt--2",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <span className="text-white h3 d-xl-none">{capitalized}</span>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <Media className="ml-2 d-flex d-lg-block align-items-center mt--3">
                      <span
                        className="mb-0 text-medium font-weight-bold"
                        style={{ cursor: "pointer" }}
                      >
                        {userName?.name === ""
                          ? userName?.Email
                          : userName?.name}
                      </span>
                      <span
                        className="btn-inner--icon ml-2"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={(e) => navigate("/a/user/profile")}>
                    <i className="ni ni-single-02" />
                    <span>Profile</span>
                  </DropdownItem>

                  <DropdownItem onClick={() => logout()}>
                    <i className="fa fa-sign-out" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
