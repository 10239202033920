const { useEffect, useState } = require("react");
const { default: CommonScreen } = require("./CommonScreen");
const { verifyTenant } = require("action/Auth/publicScreen");
const { useLocation } = require("react-router-dom");

const UserVerifyEmail = () => {
    const location = useLocation();
    const [screenData, setScreenData] = useState(null);
    const pathSegments = location.pathname.split("/");
    const uuid = pathSegments[pathSegments.length - 1];
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await verifyTenant(uuid);
          if (res.code === 200) {
            setScreenData({
              title: "Thank you",
              description: "Your email has been successfully verified. You may now log in.",
              button: "Login",
              color: "black",
            });
          } else {
            setScreenData({
              title: `${res.code} Error`,
              description: `The link has expired. Kindly resend the verification email and try again.`,
              button: "Login",
              color: "red",
            });
          }
        } catch (error) {
          setScreenData({
            title: "Error",
            description: "An error occurred while verifying your email.",
            button: "Login",
            color: "red",
          });
        }
      };
      fetchData();
    }, [uuid]);
  
    if (!screenData) {
      return <div>Loading...</div>;
    }
  
    return (
      <CommonScreen
        title={screenData.title}
        description={screenData.description}
        button={screenData.button}
        color={screenData.color}
      />
    );
  };

  export default UserVerifyEmail