import { calendarData } from "constants/Calendar/calendar";

const initialState = {
  calendarData: [],
};

const calendar = (state = initialState, action) => {
  switch (action.type) {
    case calendarData:
      return { ...state, calendarData: action.payload };

    default:
      return state;
  }
};

export default calendar;
