/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
} from "reactstrap";
import ServerIndicator from "components/Common/ServerIndicator";

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  const [state, setState] = React.useState({});
  const [isButtonHovered, setIsButtonHovered] = React.useState(false); // new state for button hover
  const [copiedText, setCopiedText] = React.useState("");
  const [collapseSupport, setCollapseSupport] = React.useState(false);

  const toggle = () => setCollapseSupport(!collapseSupport);

  const location = useLocation();
  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, [routes]);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
    setIsButtonHovered(true); // show the full button on hover
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
    setIsButtonHovered(false); // hide the full button on leave
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  const createLinks = (routes) => {
    const userRole = JSON.parse(localStorage.getItem("p"))?.role;

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      // Check if userRole is present in the roles array of the current route
      const hasAccess = prop.roles?.includes(userRole);

      if (prop.collapse && prop.show) {
        if (!hasAccess) return null;

        const st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames(activeRoute(prop.layout + prop.path), {
                "bg-secondary": activeRoute(prop.layout + prop.path),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon">{prop.miniName}</span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      if (prop.show && hasAccess) {
        return (
          <NavItem
            className={classnames(activeRoute(prop.layout + prop.path), {
              "bg-secondary": activeRoute(prop.layout + prop.path),
            })}
            key={key}
          >
            <NavLink
              to={prop.layout + prop.path}
              onClick={closeSidenav}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName !== undefined ? (
                <>
                  <span className="sidenav-mini-icon">{prop.miniName}</span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : (
                prop.name
              )}
            </NavLink>
          </NavItem>
        );
      }

      return null;
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  //when clicked on contact email
  const handleEmailSupport = () => {
    window.location.href = "mailto:support@bulkcalendar.co";
    // window.open("mailto:support@bulkcalendar.co");
  };

  //when clicked on contact whatapp
  const handleWhatsAppSupport = () => {
    window.open("https://wa.me/message/7V2R562INJAWH1");
  };

  //when clicked on contact call
  const handleCallSupport = () => {
    window.location.href = "tel:+918625080811";
  };

  //on click of copy icon
  const handleCopyClick = async (e, text) => {
    e.stopPropagation();
    try {
      await window.navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText("");
      }, 1000);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };

  const scrollBarInner = (
    <div
      className="scrollbar-inner d-flex flex-column"
      style={styles.scrollContainer}
    >
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand className="align-items-center ml-lg-auto mt-2">
            <div className="d-flex align-content-center">
              <img
                alt="..."
                className="navbar-brand-img"
                src={require("assets/img/brand/bulk-logo.png")}
                style={styles.imgStyle}
              />
              <p style={styles.textStyle}>BulkCalendar</p>
            </div>
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
      <div>
        <ServerIndicator />
      </div>
      <div className="d-flex flex-column" style={styles.contact}>
        {(isButtonHovered && !sidenavOpen) || sidenavOpen ? (
          <>
            <span
              className="d-flex align-items-center justify-content-between"
              onClick={toggle}
              style={styles.cursor}
            >
              <span>
                <i className="fa-solid fa-phone fa-sm mr-3 ml-2" />
                Contact Support{" "}
              </span>
              <i
                className={`fa-solid fa-chevron-${
                  collapseSupport ? "down" : "right"
                } fa-sm `}
                style={{ color: "#ccc" }}
              />
            </span>
            <Collapse isOpen={collapseSupport}>
              <div
                className="d-flex my-3 flex-column"
                style={{ rowGap: "0.8rem" }}
              >
                <span
                  className="d-flex align-items-center flex-wrap"
                  style={styles.contactItem}
                >
                  <Button
                    className="btn-icon-only btn-sm rounded-circle mr-2"
                    color="facebook"
                    type="button"
                    onClick={() => handleEmailSupport()}
                  >
                    <span>
                      <i className="fa-solid fa-envelope" />
                    </span>
                  </Button>
                  <span
                    onClick={() => handleEmailSupport()}
                    style={styles.cursor}
                  >
                    support@bulkcalendar.co
                  </span>
                  {copiedText === `support@bulkcalendar.co` ? (
                    <span className="ml-1" style={styles.contactItem}>
                      copied!
                    </span>
                  ) : (
                    <div
                      className="icon-action ml-2"
                      style={styles.cursor}
                      onClick={(e) => {
                        handleCopyClick(e, "support@bulkcalendar.co");
                      }}
                    >
                      <i className="fa-regular fa-copy fa-sm" />
                    </div>
                  )}
                </span>
                <span
                  className="d-flex align-items-center"
                  style={styles.contactItem}
                >
                  <Button
                    className=" btn-icon-only rounded-circle btn-sm mr-2"
                    color="success"
                    type="button"
                    onClick={() => handleWhatsAppSupport()}
                  >
                    <span>
                      <i className="fab fa-whatsapp fa-lg" />
                    </span>
                  </Button>{" "}
                  <span
                    onClick={() => handleWhatsAppSupport()}
                    style={styles.cursor}
                  >
                    Whatsapp Chat/Call
                  </span>
                </span>
                <span
                  className="d-flex align-items-center"
                  style={styles.contactItem}
                >
                  <Button
                    className="btn-icon-only rounded-circle btn-sm mr-2"
                    color="twitter"
                    type="button"
                    onClick={() => handleCallSupport()}
                  >
                    <span>
                      <i className="fa-solid fa-phone" />
                    </span>
                  </Button>
                  <span
                    onClick={() => handleCallSupport()}
                    style={styles.cursor}
                  >
                    +91-8625-080811
                  </span>
                  {copiedText === `+918625080811` ? (
                    <span className="ml-1" style={styles.contactItem}>
                      copied!
                    </span>
                  ) : (
                    <div
                      className="icon-action ml-2"
                      style={styles.cursor}
                      onClick={(e) => {
                        handleCopyClick(e, "+918625080811");
                      }}
                    >
                      <i className="fa-regular fa-copy fa-sm" />
                    </div>
                  )}
                </span>
              </div>
            </Collapse>
            <Button
              color="warning"
              className="btn-icon mt-4"
              style={styles.button}
              onClick={() =>
                window.open(
                  "https://calendar.app.google/a7SVNUnqNxsyxdwR8",
                  "_blank"
                )
              }
            >
              <i
                className="fa fa-calendar-check"
                aria-hidden="true"
                style={{ marginRight: "0.6rem" }}
              ></i>
              Book a Demo
            </Button>
          </>
        ) : (
          <>
            <span>
              <i className="fa-solid fa-phone fa-sm mr-3 ml-3" />
            </span>
            <Collapse isOpen={collapseSupport}>
              <div
                className="d-flex my-3 flex-column"
                style={{ rowGap: "0.8rem" }}
              >
                <Button
                  className="btn-icon-only rounded-circle btn-sm align-self-center"
                  color="facebook"
                  type="button"
                >
                  <span>
                    <i className="fa-solid fa-envelope" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle btn-sm"
                  color="success"
                  type="button"
                >
                  <span>
                    <i className="fab fa-whatsapp fa-lg" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle btn-sm"
                  color="twitter"
                  type="button"
                >
                  <span>
                    <i className="fa-solid fa-phone" />
                  </span>
                </Button>
              </div>
            </Collapse>
            <Button
              className="btn-icon-only rounded btn-sm mt-4 align-self-center"
              color="warning"
              type="button"
            >
              <span>
                <i className="fa fa-calendar-check" />
              </span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
      style={{ overflow: "hidden" }}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};
Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;

const styles = {
  button: {
    fontSize: "1.2rem",
  },
  imgStyle: {
    width: "35px",
    height: "33px",
  },
  textStyle: {
    fontFamily: "rubik",
    color: "#40BBEC",
    fontSize: "22px",
    fontWeight: 500,
    textTransform: "none",
    margin: "auto",
  },
  contact: {
    marginTop: "auto", // This will push it to the bottom of the flex container
    padding: "0.5rem",
    transition: "padding 0.3s",
  },
  scrollContainer: { overflowY: "auto", overflowX: "hidden" },
  contactItem: { fontSize: "0.9rem" },
  cursor: { cursor: "pointer" },
};
