import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";

const CommonScreen = (props) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    //Done to make the text and logo responsive
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const navigate = useNavigate();
  const styles = {
    thankYouContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f0f4f8",
    },
    thankYouCard: {
      textAlign: "center",
    },
    thankYouMessage: {
      fontSize: "1rem",
      color: props.color,
    },
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    image: {
      width:
        windowWidth <= 576
          ? "40px"
          : windowWidth <= 768
          ? "50px"
          : windowWidth <= 1200
          ? "40px"
          : "50px",
      height:
        windowWidth <= 576
          ? "35px"
          : windowWidth <= 768
          ? "45px"
          : windowWidth <= 1200
          ? "35px"
          : "45px",
    },
    text: {
      fontFamily: "rubik",
      color: "#40BBEC",
      fontSize:
        windowWidth <= 576
          ? "24px"
          : windowWidth <= 768
          ? "28px"
          : windowWidth <= 1200
          ? "24px"
          : "28px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "5px", // Spacing between image and text
      marginBottom: 0, // Ensure no extra margin below the text
    },
  };
  return (
    <Row className="justify-content-center mt-5">
      <Col md="4" lg="4">
        <Card style={styles.thankYouCard}>
          <CardHeader>
            <div
              style={styles.container}
              className="align-items-center d-flex mt-2 mb-1"
            >
              <img
                alt="..."
                className="navbar-brand-img align-items-center d-flex"
                src={require("assets/img/brand/bulk-logo.png")}
                style={styles.image}
              />
              <p style={styles.text}>BulkCalendar</p>
            </div>
            <h2>
              {props.res?.code} {props.title}
            </h2>
          </CardHeader>
          <CardBody>
            <p style={styles.thankYouMessage}>{props.description}</p>
            {props.button ? (
              <Button
                color="info"
                onClick={() => {
                  navigate("/auth/login");
                }}
              >
                {props.button}
              </Button>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CommonScreen;
