import * as api from "../../API/index";

export const verifyUuid = async (uuid) => {
  try {
    const res = await api.verify_uuid(uuid);
    return res.data
  } catch (error) {
    return error.response.data;
  }
};

export const verifyTenant = async (uuid) => {
  try {
    const res = await api.verify_tenant(uuid);
    return res.data
  } catch (error) {
    return error.response.data;
  }
};