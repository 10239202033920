import { combineReducers } from "redux";
import campaignListReducers from "./CampaignReducers/campaignList";
import EmailConfigReducers from "./SettingsReducers/emailConfig";
import SubUser from "./SettingsReducers/SubUser";
import Profile from "./SettingsReducers/profile";
import Organization from "./SettingsReducers/organization";
import Plans from "./Billing/Plans";
import createCampaign from "./CampaignReducers/createCampaign";
import unsubscribe from "./unsubscribe/unsubscribe";
import calendar from "./Calendar/calendar";
import Dashboard from "./dashboard";

export const reducers = combineReducers({
  campaignListReducers,
  EmailConfigReducers,
  SubUser,
  Profile,
  Organization,
  Plans,
  createCampaign,
  unsubscribe,
  calendar,
  Dashboard,
});
