//this component is used to know that on which server the project is running
// i.e live, ros(testing),local

function ServerIndicator() {
  //https://console.bulkcalendar.com/auth/login
  //http://bulkcalendar.rubaru.shop/admin/create-invite-step-1
  const nowLocation = window.location.href;
  const currentServer =
    nowLocation.split("/")[2] === "console.bulkcalendar.com"
      ? "live"
      : nowLocation.split("/")[2] === "bulkcalendar.rubaru.shop"
      ? "testing"
      : "local";

  return (
    currentServer !== "live" && (
      <small
        className={`${
          currentServer === "local" ? "bg-primary" : "bg-warning"
        } text-white px-2`}
        style={{ fontSize: "0.2rem" }}
      >
        {currentServer}
      </small>
    )
  );
}

export default ServerIndicator;
