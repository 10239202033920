import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import CountUp from "react-countup";

function CountsCard({ campaignDetails, inviteCount, showStatus }) {
  return (
    <>
      {showStatus && (
        <Col md={12} className="shadow bg-white mb-4 pl-3 p-2 rounded">
          <Label>Campaign Status - </Label>
          <Label
            className="ml-2"
            style={{
              fontWeight: "500",
              color:
                campaignDetails?.status === "FAILED"
                  ? "red"
                  : campaignDetails?.status === "IN-PROGRESS"
                  ? "orange"
                  : campaignDetails?.status === "COMPLETED"
                  ? "green"
                  : campaignDetails?.status === "RETRY"
                  ? "Brown"
                  : campaignDetails?.status === "MODIFIED"
                  ? "purple"
                  : "SteelBlue", //for PENDING
            }}
          >
            {campaignDetails?.status}
          </Label>
          {campaignDetails?.status === "RETRY" && (
            <span className="text-muted ml-2">
              (System will retry sending invites in sometime.)
            </span>
          )}
        </Col>
      )}
      <Col>
        <Row>
          <Col md="4" xl="2" className="p-0 pr-xl-3 pr-sm-3">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-primary">
                      <CountUp
                        end={campaignDetails?.attendeeCount}
                        duration={3}
                      />
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" xl="2" className="p-0 pr-xl-3 pr-sm-3">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Pending
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-info">
                      {campaignDetails?.status === "IN-PROGRESS" ? (
                        campaignDetails?.unsendCount - inviteCount.sentCount
                      ) : (
                        <CountUp
                          end={campaignDetails?.unsendCount}
                          duration={3}
                        />
                      )}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" xl="2" className="p-0 pr-xl-3">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="d-flex text-uppercase text-muted mb-0 justify-content-between"
                    >
                      Sent
                      <span>
                        <i
                          className="fa fa-info-circle fa-sm"
                          id="tooltip969372949"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip969372949"
                        >
                          Sent count includes all invites sent through this
                          campaign, including test invites.
                        </UncontrolledTooltip>
                      </span>
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-success">
                      {campaignDetails?.status === "IN-PROGRESS" ? (
                        campaignDetails?.sentCount + inviteCount.sentCount
                      ) : (
                        <CountUp
                          end={campaignDetails?.sentCount}
                          duration={3}
                        />
                      )}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" xl="2" className="p-0 pr-xl-3 pr-sm-3">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Failed
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-red">
                      {campaignDetails?.status === "IN-PROGRESS" ? (
                        campaignDetails?.failedCount + inviteCount.failedCount
                      ) : (
                        <CountUp
                          end={campaignDetails?.failedCount}
                          duration={3}
                        />
                      )}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" xl="2" className="p-0 pr-xl-3 pr-sm-3">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Unsubscribed
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-warning">
                      <CountUp
                        end={campaignDetails?.unsubscribedCount}
                        duration={3}
                      />
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="4" xl="2" className="p-0">
            <Card className="card-stats h-75">
              <CardBody>
                <Row>
                  <Col>
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Don't Send
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 mr-1 text-yellow">
                      <CountUp
                        end={campaignDetails?.dontSendCount}
                        duration={3}
                      />
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default CountsCard;
