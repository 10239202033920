import { authorizedUser } from "components/CommonFunctions";
import * as api from "../../API/index";
import { dashboardData } from "constants/dashboard";

export const getDashboardData = async (dispatch) => {
  try {
    const res = await api.get_dashboard_data();
    dispatch({ type: dashboardData, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: dashboardData, payload: error });
  }
};
