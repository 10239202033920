// //local site key and base url
// export const BASE_URL = "http://localhost:3000/core";
// export const SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

//ros
//export const BASE_URL = "https://bulkcalendar.rubaru.shop/core";
//export const SITE_KEY = "6LcIqjIqAAAAAPrZ9XBhcdFlto7PVBiV_6lf9Xe8";

//production
 export const SITE_KEY="6Les_TwqAAAAAIMgEzeyOrNv-JbdWEmJEwouY8Zk"
 export const BASE_URL = "https://console.bulkcalendar.com/core";

//user Registration link
export const USER_REGISTRATION = `${BASE_URL}/tenant/registration`;
export const USER_LOGOUT = `${BASE_URL}/logout`;
export const SUB_USER_REGISTRATION = `${BASE_URL}/register-sub-user/`;
export const USER_LOGIN = `${BASE_URL}/login`;
export const GOOGLE_LOGIN = `${BASE_URL}/user/google/login`;
export const GOOGLE_SIGN_UP = `${BASE_URL}/user/google/sign-up`;
export const GOOGLE_CONFIG = `${BASE_URL}/email-configuration/google/add`;

//tenant email verification
export const VERIFY_TENANT = `${BASE_URL}/verify-email/`;

//ResetPassword API
export const FORGOT_PASSWORD = `${BASE_URL}/forgot-password`;
export const RESET_PASSWORD = `${BASE_URL}/reset-password`;

//Setting invite user API url
export const INVITE_SUB_USER = `${BASE_URL}/tenant/sub-user/invite`;
export const SUB_USER_LIST = `${BASE_URL}/user/list?page=1`;
export const DELETE_SUB_USER = `${BASE_URL}/user/`;
export const RESEND_VERIFY_EMAIL = `${BASE_URL}/tenant/resend-verification-email`;
export const INVITED_SUB_USER = `${BASE_URL}/user/invited-sub-users`;

//mailing service
export const GET_MAILING_SERVICE_LIST = `${BASE_URL}/mailing-service/list`;
export const ADD_EMAIL_CONFIGURATION = `${BASE_URL}/email-configuration/`;
export const GET_EMAIL_CONFIGURATION_LIST = `${BASE_URL}/email-configuration/list`;

//Profile API
export const GET_PROFILE_DETAILS = `${BASE_URL}/user/profile`;
export const GET_TIMEZONE = `${BASE_URL}/time-zone/list`;
export const UPDATE_PROFILE = `${BASE_URL}/user/update-profile`;

// verify email for registration and forgot password
export const VERIFY_UUID = `${BASE_URL}/register-sub-user/`;

//organization API
export const GET_ORGANIZATION_DETAIL = `${BASE_URL}/tenant/details`;
export const UPDATE_TENANT = `${BASE_URL}/tenant/update`;

//Country list API
export const COUNTRY_LIST = `${BASE_URL}/country/list`;

//Campaign module
export const CREATE_CAMPAIGN_STEP1 = `${BASE_URL}/campaign/step1/`;
export const CREATE_CAMPAIGN_STEP2 = `${BASE_URL}/campaign/step2/`;
export const GET_ATTENDEE_LIST = `${BASE_URL}/campaign-attendee/list`;
export const ATTENDEE = `${BASE_URL}/campaign-attendee/`;
export const UPDATE_ATTENDEE = `${BASE_URL}/campaign-attendee/update`;
export const CAMPAIGN_LIST = `${BASE_URL}/campaign/list`;
export const SEND_TEST_INVITE_EMAIL = `${BASE_URL}/campaign/test/send`;
export const GET_CAMPAIGN_DETAILS = `${BASE_URL}/campaign/`;
export const SEND_CAMPAIGN = `${BASE_URL}/campaign/send/`;
export const GET_INVITE_COUNT = `${BASE_URL}/campaign/invite-count/`;

//Plans screen API
// export const CREATE_SUBSCRIPTION = `${BASE_URL}/payment/create-subscription/`;
export const CREATE_ORDER = `${BASE_URL}/payment/create-order`;
export const STORE_PAYMENT_DATA = `${BASE_URL}/payment/process-order-payment`;
// export const STORE_PAYMENT_DATA = `${BASE_URL}/payment/process-plan-subscription`;
export const PLAN_LIST = `${BASE_URL}/tenant-plan/list`;
export const CHECK_PAYMENT_STATUS = `${BASE_URL}/payment/check-razorpay-payment-status`;
export const CANCEL_PLAN = `${BASE_URL}/payment/cancel-subscription`;
export const PAYPAL_PLAN_SUBSCRIPTION = `${BASE_URL}/payment/process-plan-subscription`;

//Unsubsciber List
export const UNSUBSCRIBER_LIST = `${BASE_URL}/unsubscriber-list/list`;
export const UNSUBSCRIBING = `${BASE_URL}/unsubscribe`;

//Check renewal of plan
export const RENEWAL_REQUIRED = `${BASE_URL}/payment/check-for-renewal-plan`;
//payment history API
export const PAYMENT_HISTORY = `${BASE_URL}/payment/history`;

//dashboard API
export const GET_DASHBOARD_DATA = `${BASE_URL}/tenant/dashboard`;
//powered by flag
export const POWERED_BY_FLAG = `${BASE_URL}/tenant-plan/powered-by-flag`;

//Calendar
export const GET_CALENDAR = `${BASE_URL}/tenant/calendar`;

// download invoice
export const DOWNLOAD_INVOICE = `${BASE_URL}/payment/download-invoice/`;
