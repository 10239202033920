import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Container,
  Row,
  Col,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import moment from "moment";
import { getCalendar } from "action/Calendar/calendar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let calendar;

function CalendarView() {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [viewType, setViewType] = useState("dayGridMonth"); // Default to Month view
  const [modalAdd, setModalAdd] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const calendarDetails = useSelector((state) => state.calendar.calendarData);
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const navigate = useNavigate();

  // Initialize Calendar (Runs only once when component mounts)
  useEffect(() => {
    calendar = new Calendar(calendarRef.current, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
      initialView: viewType,
      // selectable: true,
      // editable: true,
      dayMaxEvents: true, //In, dayGrid view, the max number of events within a given day
      events: events,
      headerToolbar: "",
      // contentHeight: "auto", // adjust height to auto
      // aspectRatio: 1.25, // Adjust the ratio if needed to control width vs height
      eventDidMount: (info) => {
        info.el.style.backgroundColor = "#007bff"; // Default blue background color
        info.el.style.color = "#fff"; // White text color for better contrast
        info.el.style.borderRadius = "4px"; // Rounded corners
        info.el.style.padding = "1px"; // Padding for better readability
        info.el.style.fontSize = "0.75rem"; // Adjust font size
        info.el.querySelector(".fc-event-dot").style.backgroundColor = "white"; // Dot color
        info.el.style.cursor = "pointer"; // Add pointer cursor on hover
        const titleEl = info.el.querySelector(".fc-event-title");
        const timeEl = info.el.querySelector(".fc-event-time");
        if (titleEl) titleEl.style.fontWeight = "bold"; // Bold title
        if (timeEl) timeEl.style.fontWeight = "bold"; // Bold time
      },

      eventContent: (arg) => {
        // Define the time format for 'dayGridMonth' view
        // const timeFormat =
        //   calendar.view.type === "dayGridMonth"
        //     ? `${moment(arg.event.start).format("h:mm A")} - ${moment(
        //         arg.event.end
        //       ).format("h:mm A")}`
        //     : "";
        const timeFormat =
          calendar.view.type === "dayGridMonth"
            ? `${moment(arg.event.start).format("h:mm A")}`
            : ""; // Empty string for other views
        return {
          html: `
            <div class="fc-event-dot" style="background-color: white;"></div>
            <div class="fc-event-time" style="font-weight: bold;">
              ${timeFormat}
            </div>
            <div style="font-weight: bold;">${arg.event.title}</div>
          `,
        };
      },
      datesSet: (info) => {
        const { start, end, view } = info;
        updateFilters(view.type, start, end); // Fetch new events based on the date range and view type
      },
      select: (info) => {
        setModalAdd(true);
      },
      eventClick: (info) => {
        setSelectedEvent(info.event._def.extendedProps);
        setModalAdd(true);
      },
    });
    calendar.render();
    setCurrentDate(calendar.view.title);

    return () => {
      calendar.destroy(); // Clean up on component unmount
    };
  }, []);

  // Update events dynamically when they change
  useEffect(() => {
    if (calendar) {
      calendar.removeAllEvents(); // Remove old events
      calendar.addEventSource(events); // Add new events
    }
  }, [events]);

  // Update filters and fetch data based on current view (Day, Week, Month)
  const updateFilters = (viewType, start, end) => {
    let filterView = "";
    let dateRange = [];

    // if (viewType === "dayGridMonth") {
    //   filterView = "Month";
    //   dateRange = [moment(start).format("YYYY-MM")]; // Get Month and Year
    //   console.log("dateRange Month", dateRange);
    // } else if (viewType === "timeGridWeek") {

    // as of not by send two dates the day or week or month view is working
    filterView = "Week";
    dateRange = [
      moment.utc(start).format("YYYY-MM-DD"),
      moment.utc(end).format("YYYY-MM-DD"),
    ]; // Start and End of the week
    // } else if (viewType === "timeGridDay") {
    //   filterView = "Day";
    //   dateRange = [moment(start).format("YYYY-MM-DD")]; // Specific Day
    // }

    // Dispatch action to fetch data based on filters
    getCalendar(
      dispatch,
      `?filtersJSON=[{"Field":"${filterView}","Condition":"eq","FilterValues":${JSON.stringify(
        dateRange
      )}}]`
    );
  };

  //a function which converts UTC to local time of user
  const convertUTCToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("YYYY-MM-DD HH:mm:ss"); // Convert UTC to local time
  };
  // Handle API response and format events
  useEffect(() => {
    if (calendarDetails && calendarDetails.length > 0) {
      const formattedData = calendarDetails.map((item) => ({
        title: item.calendarTitle,
        description: item.calendarDescription,
        start: convertUTCToLocal(item.startAt), // Convert UTC to local
        end: convertUTCToLocal(item.endAt),
        startLocal: convertUTCToLocal(item.startAt), // Convert UTC to local
        endLocal: convertUTCToLocal(item.endAt),
        ...item,
      }));
      setEvents(formattedData); // Set events to state
    } else {
      setEvents([]);
    }
  }, [calendarDetails]);

  //on chnage of calendar view
  const changeView = (newView) => {
    calendar.changeView(newView);
    setCurrentDate(calendar.view.title);
    setViewType(newView); // Set new view type
  };

  //to close events modal
  const closeEventsModal = () => {
    setModalAdd(false);
    setSelectedEvent(null);
  };

  //to replace the placholders if any in calendarDescription
  const getReplacedValue = (text) => {
    // Placeholder mapping
    const replacements = {
      // "{email-subject}": reReplace(selectedEvent?.emailSubject),
      "{event-start-datetime}": `${moment(selectedEvent?.startLocal).format(
        "D MMM YYYY, h:mm a"
      )}`,
      "{event-end-datetime}": `${moment(selectedEvent?.endLocal).format(
        "D MMM YYYY, h:mm a"
      )}`,
      "{event-meet-url}": selectedEvent?.eventData?.onlineEventUrl,
      "{event-location}": selectedEvent?.eventData?.locationName,
      "{unsubscribe-link}": "<a href='#'>Unsubscribe</a>",
    };

    // Replace placeholders using a regular expression
    //The regular expression /\{[^\}]+\}/g matches any text within curly braces {}.
    //The callback function checks if the matched placeholder exists in the replacements map
    // and replaces it accordingly. If no replacement is found, the original placeholder is returned.
    return text?.replace(
      /\{[^\}]+\}/g,
      (match) => replacements[match] || match
    );
  };

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar mt--6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                  {currentDate}
                </h6>
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 ml-3">
                  Event Calendar
                </h6>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                <Button
                  className="fullcalendar-btn-prev btn-neutral"
                  color="default"
                  onClick={() => {
                    calendar.prev();
                    setCurrentDate(calendar.view.title);
                  }}
                  size="sm"
                >
                  <i className="fas fa-angle-left" />
                </Button>
                <Button
                  className="fullcalendar-btn-next btn-neutral"
                  color="default"
                  onClick={() => {
                    calendar.next();
                    setCurrentDate(calendar.view.title);
                  }}
                  size="sm"
                >
                  <i className="fas fa-angle-right" />
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="month"
                  onClick={() => changeView("dayGridMonth")}
                  size="sm"
                >
                  Month
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="basicWeek"
                  onClick={() => changeView("timeGridWeek")}
                  size="sm"
                >
                  Week
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  data-calendar-view="basicDay"
                  onClick={() => changeView("timeGridDay")}
                  size="sm"
                >
                  Day
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="card-calendar">
              <CardHeader>
                <h5 className="h3 mb-0">Calendar</h5>
              </CardHeader>
              <CardBody className="p-0">
                <div
                  id="calendar"
                  ref={calendarRef}
                  style={{
                    fontSize: "0.85rem", // Reduce font size
                  }}
                />
              </CardBody>
            </Card>
            <Modal
              isOpen={modalAdd}
              toggle={() => {
                closeEventsModal();
              }}
            >
              <ModalHeader toggle={closeEventsModal} className="pb-0">
                {selectedEvent?.campaignName}
              </ModalHeader>
              <ModalBody className="pb-5 mt-0 pt-2">
                {selectedEvent ? (
                  <>
                    <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                      <i className="fa-solid fa-square mr-3 mt-1" />
                      <div>
                        <p className="m-0" style={styles.detailsText}>
                          {selectedEvent?.calendarTitle}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                      <i className="fa-solid fa-calendar-check text-black mr-3 mt-1" />
                      <p className="m-0" style={styles.detailsText}>
                        {/* date in this format 18 Oct 2024, 12:41 pm */}
                        {moment(selectedEvent?.startLocal).format(
                          "D MMM YYYY, h:mm a"
                        )}{" "}
                        -{" "}
                        {moment(selectedEvent?.endLocal).format(
                          "D MMM YYYY, h:mm a"
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                      {selectedEvent.eventType === "ONLINE" ? (
                        <i className="fa-solid fa-video text-black mr-3 mt-1"></i>
                      ) : (
                        <i className="fa-solid fa-location-dot text-black mr-3 mt-1" />
                      )}
                      <p className="m-0" style={styles.detailsText}>
                        {selectedEvent.eventType === "ONLINE" ? (
                          <a
                            href={selectedEvent?.eventData?.onlineEventUrl}
                            target="_blank"
                          >
                            {selectedEvent?.eventData?.onlineEventUrl}
                          </a>
                        ) : (
                          selectedEvent?.eventData?.locationName
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                      <i className="fa-solid fa-bars text-black mr-3 mt-1" />
                      <p className="m-0" style={styles.detailsText}>
                        {getReplacedValue(selectedEvent?.calendarDescription)}
                      </p>
                    </div>
                    {(selectedEvent?.firstName !== "" ||
                      selectedEvent?.lastName !== "") && (
                      <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                        <i className="fa-solid fa-square-plus text-black mr-3 mt-1" />
                        <p className="m-0" style={styles.detailsText}>
                          Created By - {" "}
                          {`${selectedEvent.firstName} ${selectedEvent.lastName}`}
                        </p>
                      </div>
                    )}
                    <div className="d-flex align-items-flex-start justify-content-space-around mt-2">
                      <i className="fa-solid fa-arrow-up-right-from-square text-black mr-3 mt-1" />
                      <p
                        className="m-0 text-blue"
                        style={{
                          ...styles.detailsText,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/a/campaigns/details", {
                            state: {
                              campaignCode: selectedEvent?.campaignCode,
                            },
                          })
                        }
                      >
                        View More
                      </p>
                    </div>
                  </>
                ) : (
                  <p>No event details available.</p>
                )}
              </ModalBody>
            </Modal>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CalendarView;

const styles = {
  detailsText: { fontSize: "1rem", fontWeight: "500" },
};
