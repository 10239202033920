import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
  UncontrolledTooltip,
  Collapse,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  formatDateWithTime,
  convertUTCToLocal,
} from "components/CommonFunctions";
import CustomPagination from "components/Common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { default as Select, components } from "react-select";
import { columnsList } from "../Common/constants";
import {
  inputTextColor,
  inputBorderColor,
  cyanBlue,
  selectedColor,
} from "components/Common/constants";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  bulkUpdateAttendee,
  getAttendeeList,
} from "action/campaign/createCampaign";
import { getCampaignDetails } from "action/campaign/createCampaign";
import ImportBulkAttendeeModal from "./ImportBulkAttendeeModal";
import AddOrEditAttendeeModal from "../Common/AddOrEditAttendeeModal";
import { setAppliedFiltersOnAttendees } from "action/campaign/createCampaign";
import ColumnFilterDropdown from "../Common/ColumnFilterDropdown";

export default function CampaignAccordian({
  campaignDetails,
  campaignCode,
  notify,
}) {
  const [showReadMoreModal, setShowReadMoreModal] = React.useState(false);
  const [showReadMoreModalDesc, setShowReadMoreModalDesc] =
    React.useState(false);
  const [modalState, setModalState] = useState({
    show: false,
    mode: "add",
    emailSendFields: {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    },
  });
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [selectedMoreCols, setSelectedMoreCols] = useState([]);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [showLoaderOnSearch, setShowLoaderOnSearch] = useState(false);
  const attendeeList = useSelector(
    (state) => state.createCampaign.attendeeList
  );
  const [filter, setFilter] = useState({
    search: "",
    status: null,
  });
  const [filterParams, setFilterParams] = useState("");
  const [attendeeListRecords, setAttendeeListRecords] = useState([]);
  const [alertModal, setAlertModal] = useState(null);
  const [openedCollapses, setoOpenedCollapses] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "DESC",
    sortParam: "",
  });

  const dispatch = useDispatch();

  //call to get attendee list
  useEffect(() => {
    //get attendee list based on selected
    if (campaignCode && pageNum) {
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );
      //to make show loader only when an api call has happend
      if (filterParams !== "") {
        setShowLoaderOnSearch(true);
      }

      //store the filter applied on list in redux
      const filters = {
        pageNum: pageNum,
        filterParams: filterParams,
        sortParam: sortConfig.sortParam,
      };
      setAppliedFiltersOnAttendees(dispatch, filters);
    }
  }, [pageNum, campaignCode, filterParams, sortConfig.sortParam]);

  //setting the state of attendee list or in other case showing loader or not found msg
  useEffect(() => {
    if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length > 0
    ) {
      const attendeeListWithCheckbox = attendeeList.CampaignAttendee.map(
        (item) => {
          return {
            ...item,
            checked: false,
          };
        }
      );
      setAttendeeListRecords(attendeeListWithCheckbox);
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
      setShowLoaderOnSearch(false);
    } else if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
      setAttendeeListRecords([]);
      setShowLoaderOnSearch(false);
    }
  }, [attendeeList]);

  //toggle the modal of import bulk attendee
  const toggleBulkModal = () => {
    setIsBulkModalOpen(!isBulkModalOpen);
  };
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setoOpenedCollapses([]);
    } else {
      setoOpenedCollapses([collapse]);
    }
  };

  //for email body
  const handleReadMore = () => {
    setShowReadMoreModal(!showReadMoreModal);
  };

  //for calendar description
  const handleReadMoreDesc = () => {
    setShowReadMoreModalDesc(!showReadMoreModalDesc);
  };

  //toggle the modal state of attendee modal
  const toggleModalState = (mode, item) => {
    let attendeeData = {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    };
    if (item) {
      attendeeData = {
        ID: item.ID,
        firstName: { value: item.firstName, isValid: true },
        email: { value: item.email, isValid: true },
        lastName: { value: item.lastName, isValid: true },
        field1: { value: item.field1, isValid: true },
        field2: { value: item.field2, isValid: true },
        field3: { value: item.field3, isValid: true },
        field4: { value: item.field4, isValid: true },
        field5: { value: item.field5, isValid: true },
      };
    }
    setModalState({
      show: !modalState.show,
      mode: mode,
      emailSendFields: attendeeData,
    });
  };

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = (height) => {
    return {
      control: (provided, state) => ({
        ...provided,
        borderColor: state.selectProps.className?.includes("is-invalid")
          ? "red"
          : inputBorderColor,
        height: height,
        minHeight: height,
        fontSize: "0.85rem",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: inputTextColor,
      }),
      menuList: (provided) => ({
        ...provided,
        fontSize: "0.8rem",
      }),
    };
  };

  //on change of filter in attendee list
  const handleFilterChange = (name, value) => {
    setFilter((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //search filters
  const handleSearch = () => {
    let querryParmFilter = "";
    //make filters query params
    if (filter.search.trim().length > 0) {
      querryParmFilter = "&";
      querryParmFilter += `searchString=${filter.search.toString()}`;
    }
    if (filter.status !== null && filter.status.value !== "") {
      querryParmFilter += `&filtersJSON=[{"field": "Status", "condition": "eq", "filterValues": [${JSON.stringify(
        filter.status.value
      )}]}]`;
    }
    setFilterParams(querryParmFilter);
  };

  //on clear filters
  const handleClear = () => {
    setFilterParams("");
    setFilter({
      search: "",
      status: null,
    });
  };

  //status change warning
  const bulkStatusChange = (status) => {
    setAlertModal(
      <ReactBSAlert
        info
        title={
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        }
        onConfirm={() => {
          showModalLoader("updating");
          bulkUpdateStatus(status);
        }}
        onCancel={() => setAlertModal(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
        btnSize="md"
      >
        {`You want to change the status of the selected ${
          attendeeListRecords.filter((item) => item.checked).length
        } records to ${status === "DONT_SEND" ? "Don't Send" : "Unsent"}?`}
      </ReactBSAlert>
    );
  };

  //to show loader while the api call is in progress
  const showModalLoader = (action) => {
    setAlertModal(
      <Modal isOpen={true} centered size="sm">
        <ModalBody>
          <div style={styles.deleteLoader}>
            <i
              className="fa fa-spinner fa-spin fa-2xl"
              style={{ color: cyanBlue }}
            />
            <h1>{action}...</h1>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  //api call to bulk update status
  const bulkUpdateStatus = async (status) => {
    const attendeeID = attendeeListRecords
      .filter((item) => item.checked)
      .map((item) => {
        return item.ID;
      });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: status,
      campaignAttendeeID: attendeeID,
    });
    if (res.code === 200) {
      successAlert("Updated");
      //call to get campaign when any thing is changed
      getCampaignDetails(dispatch, campaignCode);
      // called attendee list with applied params of search or sort  abd page if any
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum, // got via props
        filterParams, //got via props
        sortConfig.sortParam //got via props
      );
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Updated", res.message[0].Msg);
      } else {
        errorAlert("Updated", res.message);
      }
    }
  };

  //for success alert
  const successAlert = (action) => {
    setAlertModal(
      <ReactBSAlert
        success
        title={`${action}!`}
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Record {action} successfully.
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  //for error alert
  const errorAlert = (action, msg) => {
    setAlertModal(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Not {action}.{msg ? msg : null}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  //to hide the alert
  const hideAlert = () => {
    setAlertModal(null);
  };

  //delete warning
  const deleteWarning = (ID) => {
    setAlertModal(
      <ReactBSAlert
        info
        title={
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        }
        onConfirm={() => {
          showModalLoader("Deleting");
          deleteAttendee(ID);
        }}
        onCancel={() => setAlertModal(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        showCancel
        btnSize="md"
      >
        {ID
          ? "You want to delete this record?"
          : `You want to delete selected ${
              attendeeListRecords.filter((item) => item.checked).length
            } records?`}
      </ReactBSAlert>
    );
  };

  //delete api for single as well as bulk delete
  const deleteAttendee = async (ID) => {
    const deleteAttendeeID = ID
      ? [ID]
      : attendeeListRecords
          .filter((item) => item.checked)
          .map((item) => {
            return item.ID;
          });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: "DELETED",
      campaignAttendeeID: deleteAttendeeID,
    });
    if (res.code === 200) {
      successAlert("Deleted");
      //call to get campaign when any thing is changed
      getCampaignDetails(dispatch, campaignCode);
      // called attendee list with applied params of search or sort  abd page if any
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum, // got via props
        filterParams, //got via props
        sortConfig.sortParam //got via props
      );
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Deleted", res.message[0].Msg);
      } else {
        errorAlert("Deleted", res.message);
      }
    }
  };

  //on check and uncheck the attendee list rows
  const handleCheckAttendeeList = (index, flagForAll) => {
    if (index === -1) {
      const attendeeListWithCheckbox = attendeeListRecords.map((item) => {
        return {
          ...item,
          checked: !flagForAll,
        };
      });
      setAttendeeListRecords(attendeeListWithCheckbox);
    } else {
      const newAttendeeList = [...attendeeListRecords];
      newAttendeeList[index] = {
        ...newAttendeeList[index],
        checked: !newAttendeeList[index].checked,
      };
      setAttendeeListRecords(newAttendeeList);
    }
  };

  //to calulate the serial number based on the page Num
  const calculateSerialNum = (index) => {
    // Calculate the serial number based on the current page number
    const serialNum =
      (pageNum - 1) * attendeeList.noOfRecordsPerPage + index + 1;

    return serialNum;
  };

  //on click of send test calendar invite button
  const sendTestCalendarInvite = () => {
    if (attendeeListRecords.filter((item) => item.checked).length === 1) {
      toggleModalState(
        "test",
        attendeeListRecords.filter((item) => item.checked)[0]
      );
    } else {
      toggleModalState("test");
    }
  };
  //in case of subject to replace again found any parameter other wise retunr only the actual value
  function reReplace(str) {
    const dynamicParmeter = [
      "{email-subject}",
      "{event-start-datetime}",
      "{event-end-datetime}",
      "{event-meet-url}",
      "{event-location}",
      "{unsubscribe-link}",
    ];
    // Placeholder mapping
    const replacements = {
      "{event-start-datetime}": campaignDetails?.startAt,
      "{event-end-datetime}": campaignDetails?.endAt,
      "{event-meet-url}": campaignDetails?.eventData?.onlineEventUrl,
      "{event-location}": campaignDetails?.eventData?.locationName,
    };

    // Base case: If no dynamic parameters are found, return the string
    const foundPlaceholder = dynamicParmeter.find((param) =>
      str?.includes(param)
    );
    if (!foundPlaceholder) {
      return str; // No placeholders found, return the final string
    }
    // Recursive case: Replace the found placeholder and recurse
    const replacedString = str.replace(
      foundPlaceholder,
      replacements[foundPlaceholder] || ""
    );
    // Call the function recursively until no more placeholders are found
    return reReplace(replacedString);
  }

  const getReplacedValue = (text) => {
    // Placeholder mapping
    const replacements = {
      "{email-subject}": reReplace(campaignDetails?.emailSubject),
      "{event-start-datetime}": campaignDetails?.startAt,
      "{event-end-datetime}": campaignDetails?.endAt,
      "{event-meet-url}": campaignDetails?.eventData?.onlineEventUrl,
      "{event-location}": campaignDetails?.eventData?.locationName,
      "{unsubscribe-link}": "<a href='#'>Unsubscribe</a>",
    };

    // Replace placeholders using a regular expression
    //The regular expression /\{[^\}]+\}/g matches any text within curly braces {}.
    //The callback function checks if the matched placeholder exists in the replacements map
    // and replaces it accordingly. If no replacement is found, the original placeholder is returned.
    return text?.replace(
      /\{[^\}]+\}/g,
      (match) => replacements[match] || match
    );
  };

  const emailBody = getReplacedValue(campaignDetails?.emailBody) || "";
  // Limit the preview length for the "read more" functionality
  const previewLength = 50;
  const isLong = emailBody.length > previewLength;
  const preview = isLong
    ? emailBody.slice(0, previewLength) + "..."
    : emailBody;
  //for calendar  description
  const calendarDescription =
    getReplacedValue(campaignDetails?.calenderDescription) || "";
  const isLongDesc = calendarDescription.length > previewLength;
  const previewDesc = isLongDesc
    ? calendarDescription.slice(0, previewLength) + "..."
    : calendarDescription;

  //for handling sor t by col and its direction
  const handleSort = (colname) => {
    let newDirection = sortConfig.direction === "ASC" ? "DESC" : "ASC";
    setSortConfig({
      key: colname,
      direction: newDirection,
      sortParam: `&sortOptionJSON={"SortBy":"${colname}","SortType":"${newDirection}"}`,
    });
  };
  return (
    <div className="pb-10">
      {alertModal}
      <AddOrEditAttendeeModal
        openModal={modalState.show}
        toggleModalState={toggleModalState}
        modalState={modalState}
        setModalState={setModalState}
        campaignCode={campaignCode}
        notify={notify}
        attendeeListRecords={attendeeListRecords}
        usedDynamicParams={campaignDetails?.placeHolders}
        pageNum={pageNum}
        setPageNum={setPageNum}
        filterParams={filterParams}
        sortParam={sortConfig.sortParam}
      />

      <Modal isOpen={showReadMoreModal} size="lg" scrollable centered>
        <ModalHeader
          toggle={() => {
            handleReadMore();
          }}
        >
          Email
        </ModalHeader>
        <ModalBody className="pt-0">
          <Label>Email Subject</Label>
          <p className="button">
            {getReplacedValue(campaignDetails?.emailSubject)}
          </p>
          <Label>Email Body</Label>

          {/* Render HTML content with dangerouslySetInnerHTML */}
          <div
            dangerouslySetInnerHTML={{
              __html: emailBody,
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={handleReadMore} color="info">
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showReadMoreModalDesc} size="lg" scrollable centered>
        <ModalHeader
          toggle={() => {
            handleReadMoreDesc();
          }}
        >
          Calendar
        </ModalHeader>
        <ModalBody className="pt-0">
          <Label>Calendar Title</Label>
          <p className="button">
            {getReplacedValue(campaignDetails?.calendarTitle)}
          </p>
          <Label>Calendar Body</Label>
          <p className="button">{calendarDescription}</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={handleReadMoreDesc} color="info">
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <ImportBulkAttendeeModal
        isBulkModalOpen={isBulkModalOpen}
        toggleBulkModal={toggleBulkModal}
        notify={notify}
        campaignCode={campaignCode}
        getAttendeeList={getAttendeeList}
        pageNum={pageNum}
        setPageNum={setPageNum}
        dispatch={dispatch}
        filterParams={filterParams}
        sortParam={sortConfig.sortParam}
      />
      <div className="accordion">
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => collapsesToggle("collapseOne")}
            aria-expanded={openedCollapses.includes("collapseOne")}
          >
            <h3 className="mb-0">Event Details</h3>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseOne")}
          >
            <CardBody>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Event Type</Label>
                  <p className="button">
                    {campaignDetails?.eventType === "ONLINE"
                      ? "Online"
                      : campaignDetails?.eventType === "OFFLINE"
                      ? "Offline"
                      : ""}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>
                    Event
                    {campaignDetails?.eventType === "ONLINE"
                      ? " Meet URL"
                      : " Location"}
                  </Label>
                  <p className="button">
                    {campaignDetails?.eventType === "ONLINE"
                      ? campaignDetails?.eventData?.onlineEventUrl
                      : campaignDetails?.eventData?.locationName}
                  </p>
                </Col>
              </Row>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Calendar Title</Label>
                  <p className="button">
                    {getReplacedValue(campaignDetails?.calendarTitle)}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Calendar Description</Label>
                  <p className="button">
                    {previewDesc}
                    {isLongDesc && (
                      <span
                        onClick={handleReadMoreDesc}
                        style={{
                          color: "gray",
                          cursor: "pointer",
                          fontSize: "0.9rem",
                        }}
                      >
                        Read More
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Email Subject</Label>
                  <p className="button">
                    {getReplacedValue(campaignDetails?.emailSubject)}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Email Body</Label>

                  {/* Render HTML content with dangerouslySetInnerHTML */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: preview,
                    }}
                  />
                  {isLong && (
                    <span
                      onClick={handleReadMore}
                      style={{
                        color: "gray",
                        cursor: "pointer",
                        fontSize: "0.9rem",
                      }}
                    >
                      Read More
                    </span>
                  )}
                </Col>
              </Row>
              <Row md={12} className="mt-2">
                <Col md={6}>
                  <Label>Created by </Label>
                  <p className="button">
                    {`${campaignDetails?.createdBy?.firstName} ${campaignDetails?.createdBy?.lastName}`}
                  </p>
                </Col>
                <Col md={6}>
                  <Label>Created At </Label>
                  <p className="button">
                    {convertUTCToLocal(campaignDetails?.createdAt)}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
        <Card className="card-plain">
          <CardHeader
            role="tab"
            onClick={() => collapsesToggle("collapseTwo")}
            aria-expanded={openedCollapses.includes("collapseTwo")}
          >
            <h3 className="mb-0">Attendee List</h3>
          </CardHeader>
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseTwo")}
          >
            <CardBody>
              <Row className="d-flex justify-content-between align-items-center">
                {/* Align Total Attendees on the left with margin-bottom on small screens */}
                {attendeeList?.totalRecords > 0 && (
                  <Col xs={12} md="auto" className="mb-3 mb-md-0">
                    Total Attendees: {attendeeList?.totalRecords}
                  </Col>
                )}
                {/* Align Select More Columns and other buttons on the right with responsive class */}
                <Col
                  className="d-flex justify-content-end flex-grow-1 flex-wrap gap-2"
                  xs="12"
                  md="auto"
                >
                  <Button
                    className="btn-icon mb-3 mb-md-0"
                    color="info"
                    type="button"
                    outline
                    onClick={(e) => {
                      toggleBulkModal();
                    }}
                    size="sm"
                    style={styles.customHeight} //added height bcoz in case of when 0 total records are their at that time the button shrinks
                  >
                    <span className="btn-inner--icon">
                      <i className="fa-solid fa-plus" />
                    </span>
                    <span className="btn-inner--text">
                      Import Bulk Attendee
                    </span>
                  </Button>
                  <Button
                    className="btn-icon mb-3 mb-md-0"
                    color="info"
                    outline
                    type="button"
                    onClick={() => toggleModalState("add")}
                    size="sm"
                    style={styles.customHeight}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa-solid fa-plus" />
                    </span>
                    <span className="btn-inner--text">Add Attendee</span>
                  </Button>
                  <Button
                    className="btn-icon mb-3 mb-md-0"
                    color="success"
                    outline
                    type="button"
                    onClick={(e) => {
                      sendTestCalendarInvite();
                    }}
                    size="sm"
                    style={styles.customHeight}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-email-83" />
                    </span>
                    <span className="btn-inner--text">
                      Send Test Calendar Invite
                    </span>
                  </Button>
                </Col>
              </Row>

              <hr className="my-3" />

              <Row className="p-0 align-items-center mb-4">
                <Col
                  xs={12}
                  md={7}
                  className="d-flex flex-wrap justify-content-start align-items-center p-0"
                >
                  {/* Search Input */}
                  <Col xs={12} sm={6} md={5} className="mb-2 mb-md-0 pr-md-0">
                    <InputGroup>
                      <InputGroupText
                        style={{
                          borderTopRighttRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroupText>
                      <Input
                        placeholder="Search"
                        type="text"
                        name="search"
                        autoComplete="off"
                        value={filter.search}
                        onChange={(e) =>
                          handleFilterChange(e.target.name, e.target.value)
                        }
                      />
                    </InputGroup>
                  </Col>
                  {/* Select Status */}
                  <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0 pr-md-0">
                    <Select
                      options={[
                        { label: "Pending", value: "UNSEND" },
                        { label: "Sent", value: "SENT" },
                        { label: "Don't Send", value: "DONT_SEND" },
                        { label: "Unsubscribed", value: "UNSUBSCRIBED" },
                        { label: "Failed", value: "FAILED" },
                      ]}
                      placeholder="Search by status"
                      onChange={(selected, e) =>
                        handleFilterChange("status", selected)
                      }
                      styles={customStyles("2.9rem")}
                      value={filter.status}
                      isSearchable={true} // enable search functionality
                      isClearable
                    />
                  </Col>
                  {/* Search Button */}
                  <Col xs="auto" className="mb-2 mb-md-0">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      outline
                      onClick={(e) => handleSearch()}
                      size="md"
                      disabled={showLoaderOnSearch}
                    >
                      {showLoaderOnSearch ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-info px-3" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Col>
                  {/* Clear Button */}
                  {filterParams !== "" && (
                    <Col xs="auto" className="mb-2 mb-md-0 p-0">
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        outline
                        onClick={() => handleClear()}
                        size="md"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Col>

                <Col
                  xs={12}
                  md={5}
                  className="d-flex justify-content-end gap-2"
                >
                  {attendeeListRecords.some((item) => item.checked) && (
                    <>
                      {/* Mark as Unsent */}
                      {attendeeListRecords.some(
                        (item) =>
                          item.checked &&
                          (item.status === "DONT_SEND" ||
                            item.status === "SENT")
                      ) && (
                        <>
                          <Button
                            className="btn-round btn-icon"
                            color="light"
                            outline
                            id="tooltipUnsent"
                            onClick={(e) => bulkStatusChange("UNSEND")}
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fa-solid fa-envelope-open"></i>
                            </span>
                            <span className="btn-inner--text">
                              Mark as Pending
                            </span>
                          </Button>
                          <UncontrolledTooltip delay={0} target="tooltipUnsent">
                            Mark Selected as Pending
                          </UncontrolledTooltip>
                        </>
                      )}
                      {attendeeListRecords.some(
                        (item) =>
                          item.checked &&
                          (item.status === "UNSEND" || item.status === "SENT")
                      ) && (
                        <>
                          <Button
                            className="btn-round btn-icon"
                            color="light"
                            id="tooltipDontSend"
                            onClick={(e) => bulkStatusChange("DONT_SEND")}
                            size="sm"
                            outline
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-close" />
                            </span>
                            <span className="btn-inner--text">
                              Mark as Don't Send
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltipDontSend"
                          >
                            Mark Selected as Don't Send
                          </UncontrolledTooltip>
                        </>
                      )}
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        id="tooltip28070728"
                        onClick={(e) => deleteWarning()}
                        size="sm"
                        outline
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">Delete</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip28070728">
                        Delete Selected
                      </UncontrolledTooltip>
                    </>
                  )}
                  <ColumnFilterDropdown
                    columnsList={columnsList}
                    selectedMoreCols={selectedMoreCols}
                    setSelectedMoreCols={setSelectedMoreCols}
                    attendeeList={attendeeList}
                  />
                </Col>
              </Row>
              {showListAlternative.loader ? (
                <div style={styles.loaderContainer}>
                  <i className="fa fa-spinner fa-spin fa-2xl text-info" />
                </div>
              ) : showListAlternative.noRecordsFound ? (
                <div style={styles.loaderContainer}>No Records Found!</div>
              ) : (
                <>
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>
                          <i
                            className={
                              attendeeListRecords.every((item) => item.checked)
                                ? "fa-solid fa-square-check fa-xl text-primary"
                                : "fa-regular fa-square fa-xl"
                            }
                            onClick={() => {
                              handleCheckAttendeeList(
                                -1,
                                attendeeListRecords.every(
                                  (item) => item.checked
                                )
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </th>
                        <th>Sr. no</th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("Email")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Email ID
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "Email" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "Email" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>

                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("FirstName")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            First Name
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "FirstName" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "FirstName" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("LastName")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Last Name
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "LastName" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "LastName" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th
                          style={styles.cursor}
                          onClick={() => handleSort("SentOn")}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            Sent On
                            <span className="d-flex align-items-center">
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "SentOn" &&
                                    sortConfig.direction === "ASC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↑
                              </span>
                              <span
                                style={{
                                  color:
                                    sortConfig.key === "SentOn" &&
                                    sortConfig.direction === "DESC"
                                      ? "gray"
                                      : "lightgray",
                                  ...styles.sortIcon,
                                }}
                              >
                                ↓
                              </span>
                            </span>
                          </span>
                        </th>
                        <th>Status</th>

                        {selectedMoreCols.length > 0 &&
                          selectedMoreCols.map((colItem) => {
                            return <th key={colItem.label}>{colItem.label}</th>;
                          })}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendeeListRecords.map((item, index) => {
                        return (
                          <tr
                            style={{
                              backgroundColor: item.checked
                                ? selectedColor
                                : "white",
                            }}
                            key={item.email}
                          >
                            <th>
                              <i
                                className={
                                  item.checked
                                    ? "fa-solid fa-square-check fa-lg text-primary"
                                    : "fa-regular fa-square fa-lg"
                                }
                                onClick={() => handleCheckAttendeeList(index)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </th>
                            <td className="text-muted">
                              {calculateSerialNum(index)}
                            </td>
                            <td className="text-muted">
                              <span>{item.email}</span>
                            </td>
                            <td className="text-muted">
                              <span>{item.firstName}</span>
                            </td>
                            <td className="text-muted">
                              <span>{item.lastName}</span>
                            </td>
                            <td className="text-muted">
                              {item.sentOn !== "" ? (
                                <span>{formatDateWithTime(item.sentOn)}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {item.status === "UNSEND" ? (
                                <span className="text-muted">Unsent</span>
                              ) : item.status === "SENT" ? (
                                <span className="text-success font-weight-bold">
                                  Sent
                                </span>
                              ) : item.status === "DONT_SEND" ? (
                                <span className="text-primary">Don't Send</span>
                              ) : item.status === "UNSUBSCRIBED" ? (
                                <span className="text-orange">
                                  Unsubscribed
                                </span>
                              ) : item.status === "FAILED" ? (
                                <span className="text-red">Failed</span>
                              ) : (
                                <span className="text-muted">
                                  {item.status}
                                </span>
                              )}
                            </td>
                            {selectedMoreCols.length > 0 &&
                              selectedMoreCols.map((colItem) => {
                                return (
                                  <td
                                    className="text-muted"
                                    key={colItem.value}
                                  >
                                    {item[colItem.value]}
                                  </td>
                                );
                              })}
                            <td>
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1rem",
                                }}
                              >
                                <a
                                  className="table-action"
                                  id={`tooltipEditRecord${item.ID}`}
                                  onClick={(e) => {
                                    toggleModalState("edit", item);
                                  }}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltipEditRecord${item.ID}`}
                                >
                                  Edit Record
                                </UncontrolledTooltip>
                                <div className="text-gray">|</div>
                                <a
                                  className="table-action table-action-delete"
                                  id={`tooltipDeleteRecord${item.ID}`}
                                  onClick={(e) => deleteWarning(item.ID)}
                                >
                                  <i className="fas fa-trash" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltipDeleteRecord${item.ID}`}
                                >
                                  Delete Record
                                </UncontrolledTooltip>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div>
                    <CustomPagination
                      totalPages={Math.ceil(
                        attendeeList.totalRecords /
                          attendeeList.noOfRecordsPerPage
                      )}
                      setPageNum={setPageNum}
                      activePage={pageNum}
                    />
                  </div>
                </>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </div>
  );
}

const styles = {
  cusrorPointer: { cursor: "pointer" },
  iconSpacing: { flexDirection: "row", columnGap: 8 },
  cursor: { cursor: "pointer" },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  actionConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
  },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  cusrorPointer: { cursor: "pointer" },
  iconSpacing: { flexDirection: "row", columnGap: 8 },
  validationError: { color: "red", fontSize: 12 },
  dynamicParamItem: {
    display: "flex",
    flexDirection: "column",
  },
  dynamicParamItemRow: {
    display: "flex",
    flexDirection: "row",
  },
  sortIcon: {
    fontSize: "1.5em",
    transform: "scale(1.2)",
  },
  customHeight: { height: "2.5rem" },
};
