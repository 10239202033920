import { calendarData } from "constants/Calendar/calendar";
import * as api from "../../API/index";
import { authorizedUser } from "components/CommonFunctions";

export const getCalendar = async (dispatch, filterParams) => {
  try {
    const res = await api.get_calendar(filterParams);
    dispatch({ type: calendarData, payload: res.data.data });
  } catch (error) {
    dispatch({ type: calendarData, payload: error });
    authorizedUser(error.response?.data);
  }
};
