import { dashboardData } from "constants/dashboard";

const initialState = {
  dashboardData: {},
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case dashboardData:
      return { ...state, dashboardData: action.payload };

    default:
      return state;
  }
};

export default Dashboard;
