import { planList } from "action/Billing/plans";
import { paypalPlanSubscription } from "action/Billing/plans";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const PaypalButton = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Load the PayPal SDK script dynamically
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${props.clientID}&vault=true&intent=subscription`;
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.onload = () => {
      if (window.paypal) {
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "blue",
              layout: "vertical",
              label: "subscribe",
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                plan_id: props.paypalPlanID,
              });
            },
            onApprove: function (data, actions) {
              props.setLoading(true);
              const planData = {
                razorpay_subscription_id: data.subscriptionID,
                recurring: "YES",
                subscription_id: data.subscriptionID,
                paymentGateway: "PAYPAL",
                plan_id: props.planID,
              };
              props.closeModal();
              setTimeout(async () => {
                const res = await paypalPlanSubscription(planData);
                props.setLoading(false);
                if (res.code === 200) {
                  planList(dispatch);
                  props.notify("success", "Your Payment is Successful.");
                } else {
                  props.notify(
                    "error",
                    "Something went wrong. Please try again."
                  );
                }
              }, 10000);
            },
            onError: function (err) {
              props.closeModal();
            },
          })
          .render("#paypal-button-container");
      }
    };
    document.body.appendChild(script);
    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="paypal-button-container"></div>;
};

export default PaypalButton;
