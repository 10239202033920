import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Label,
  Modal,
} from "reactstrap";
// core components
import Select from "react-select";
import { getOrganizationDetail } from "action/Settings/organization";
import { useDispatch, useSelector } from "react-redux";
import { updateTenant } from "action/Settings/organization";
import { getCountryList } from "action/Settings/organization";
import {
  inputBorderColor,
  inputTextColor,
} from "../../components/Common/constants";
import NotificationAlert from "react-notification-alert";
import { planList } from "action/Billing/plans";
import { useNavigate } from "react-router-dom";
import { poweredByFlagChange } from "action/Settings/organization";
import Swal from "sweetalert2";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.className?.includes("is-invalid")
      ? "red"
      : inputBorderColor,
    height: "2.8rem",
    fontSize: "0.85rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: inputTextColor,
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: "0.8rem",
  }),
};

function Organization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationDetails = useSelector((state) => state.Organization);
  const [disableButton, setDisableButton] = React.useState(true);
  const [handleModal, setHandleModal] = React.useState(false);
  const [formValues, setFormValues] = useState({
    organizationName: "",
    websiteUrl: "",
    pincode: 0,
    state: "",
    city: "",
    lineOne: "",
    lineTwo: "",
    countryID: 0,
  });

  const [errors, setErrors] = useState({
    organizationName: "",
    websiteUrl: "",
    pincode: "",
    state: "",
    city: "",
    lineOne: "",
    lineTwo: "",
    countryID: "",
  });

  const [isHovered, setIsHovered] = React.useState(false);
  const [disabledFields, setDisabledFields] = useState({});
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const planData = useSelector((state) => state.Plans.planListData);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    getOrganizationDetail(dispatch);
    getCountryList(dispatch);
    //Called planList to check the plan is Free or not for email settings
    planList(dispatch);
  }, []);

  React.useEffect(() => {
    if (organizationDetails?.organizationDetails) {
      const details = organizationDetails?.organizationDetails;
      setFormValues({
        organizationName: details.organizationName || "",
        websiteUrl: details.websiteUrl || "",
        pincode: details.pincode || "",
        state: details.state || "",
        city: details.city || "",
        lineOne: details.lineOne || "",
        lineTwo: details.lineTwo || "",
        countryID: details.countryResponse?.ID || 0,
      });
      setDisabledFields({
        organizationName: details.organizationName ? true : false,
        websiteUrl: details.websiteUrl ? true : false,
        pincode: details.pincode ? true : false,
        state: details.state ? true : false,
        city: details.city ? true : false,
        lineOne: details.lineOne ? true : false,
        lineTwo: details.lineTwo ? true : false,
        countryID: details.countryResponse?.ID ? true : false,
      });
    }
  }, [organizationDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    const finalValue = trimmedValue === "" ? "" : value;
    setDisableButton(false);
    // Update form values
    setFormValues({
      ...formValues,
      [name]: finalValue,
    });

    handleBlur(e); // Call handleBlur to handle any additional validation or effects
  };

  const handleSelectChange = (e) => {
    setFormValues({ ...formValues, countryID: e.ID });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim(); // Trim the value to remove any leading or trailing spaces
    let isValid = true;

    switch (name) {
      case "websiteUrl":
        const websitePattern =
          /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,6}(\/[\w .-]*)*\/?$/;
        isValid = websitePattern.test(trimmedValue);
        if (!isValid && value) {
          setErrors({
            ...errors,
            [name]: "Please enter a valid website URL",
          });
        } else {
          setErrors({
            ...errors,
            [name]: "",
          });
          setFormValues({
            ...formValues,
            [name]: trimmedValue,
          });
        }
        break;

      case "pincode":
        const pincodePattern = /^[0-9]{6}$/;
        isValid = pincodePattern.test(trimmedValue);
        if (!isValid && value) {
          setErrors({
            ...errors,
            [name]: "Please enter a valid 6-digit pincode",
          });
        } else {
          setErrors({
            ...errors,
            [name]: "",
          });
        }
        break;

      default:
        setErrors({
          ...errors,
          [name]: "",
        });

        break;
    }
  };

  const addOrganizationName = () => {
    const storedObject = localStorage.getItem("p");
    let user = storedObject ? JSON.parse(storedObject) : {};
    const newName = `${formValues.organizationName}`;
    user = {
      ...user,
      organizationName: newName,
    };
    localStorage.setItem("p", JSON.stringify(user));
  };

  const handleSubmit = async () => {
    const res = await updateTenant(formValues);
    if (res?.code === 200) {
      setDisableButton(true);
      notify("success", "You information updated successfully.");
      getOrganizationDetail(dispatch);
      addOrganizationName();
    } else {
      notify(
        "error",
        res?.message
          ? res.message
          : "Something went wrong please try after some time."
      );
    }
  };

  const handleDisable = () => {
    if (!hasErrors) {
      return disableButton;
    } else {
      return true;
    }
  };
  //To change the powered by flag
  const handleCheckBox = async (e) => {
    if (planData?.tenantActivePlan) {
      if (planData?.tenantActivePlan?.planID === 1) {
        setHandleModal(!handleModal);
      } else {
        Swal.fire({
          title: "Please wait...",
          html: '<i class="fa fa-spinner fa-spin fa-2xl m-5 text-info"></i>',
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        const res = await poweredByFlagChange({
          poweredByFlag: e.target.checked === false ? "YES" : "NO",
        });

        if (res?.data?.code === 200) {
          getOrganizationDetail(dispatch);
          Swal.fire({
            title: "Changed Successfully",
            text: "The update has been successful",
            timerProgressBar: true,
            timer: 3000,
            icon: "success",
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Something went wrong",
            text: `Try again later.`,
            timerProgressBar: true,
            timer: 3000,
            icon: "error",
            showConfirmButton: false,
          });
        }
      }
    } else {
      Swal.fire({
        title: "Something went wrong",
        text: `Try again later.`,
        timerProgressBar: true,
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    }
  };

  const handleModalChange = () => {
    setHandleModal(!handleModal);
  };

  const hasErrors = Object.values(errors).some((error) => error);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Company Name" /> */}
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Organization</h3>
            <h3 style={styles.noteTextStyle}>
              Please note : Below information will be used for Invoicing purpose
              and Once submitted, this information cannot be altered. For any
              modifications, kindly contact us at :&nbsp;
              <a
                href="mailto:support@bulkcalendar.co"
                style={{
                  textDecoration: isHovered ? "underline" : "none",
                  color: "inherit",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                support@bulkcalendar.co
              </a>
            </h3>
          </CardHeader>
          <CardBody>
            <>
              <h4>Organization Information</h4>
              <Row className="mt-3">
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input
                      name="organizationName"
                      autoComplete="off"
                      disabled={disabledFields.organizationName}
                      placeholder="Name"
                      type="text"
                      value={formValues.organizationName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.organizationName}
                    />
                    {errors.organizationName && (
                      <small className="text-danger">
                        {errors.organizationName}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Website</label>
                    <Input
                      name="websiteUrl"
                      disabled={disabledFields.websiteUrl}
                      autoComplete="off"
                      placeholder="Website"
                      type="text"
                      value={formValues.websiteUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.websiteUrl}
                    />
                    {errors.websiteUrl && (
                      <small className="text-danger">{errors.websiteUrl}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <h4>Address Information</h4>
              <Row className="mt-3">
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Line one</label>
                    <Input
                      name="lineOne"
                      disabled={disabledFields.lineOne}
                      placeholder="Line One"
                      type="text"
                      value={formValues.lineOne}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.lineOne}
                    />
                    {errors.lineOne && (
                      <small className="text-danger">{errors.lineOne}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Line two</label>
                    <Input
                      name="lineTwo"
                      placeholder="Street Address"
                      disabled={disabledFields.lineTwo}
                      type="text"
                      value={formValues.lineTwo}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.lineTwo}
                    />
                    {errors.lineTwo && (
                      <small className="text-danger">{errors.lineTwo}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Country</label>
                    <Select
                      options={organizationDetails?.countryList}
                      placeholder="Select Country"
                      isDisabled={disabledFields.countryID}
                      name="countryID"
                      getOptionLabel={(options) => options.name}
                      getOptionValue={(options) => options.ID}
                      onChange={(selected, e) =>
                        handleSelectChange(selected, e)
                      }
                      styles={customStyles}
                      value={
                        organizationDetails?.countryList?.find(
                          (option) => option.ID === formValues.countryID
                        ) || ""
                      } // find the selected option based on value
                      isSearchable={true} // enable search functionality
                      className={!!errors.timeZone ? "is-invalid" : null}
                    />
                    {errors.countryID && (
                      <small className="text-danger">{errors.countryID}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">State</label>
                    <Input
                      name="state"
                      placeholder="State"
                      disabled={disabledFields.state}
                      type="text"
                      autoComplete="off"
                      value={formValues.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.state}
                    />
                    {errors.state && (
                      <small className="text-danger">{errors.state}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">City</label>
                    <Input
                      name="city"
                      disabled={disabledFields.city}
                      placeholder="City"
                      type="text"
                      value={formValues.city}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.city}
                    />
                    {errors.city && (
                      <small className="text-danger">{errors.city}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label">Postal Code</label>
                    <Input
                      name="pincode"
                      disabled={disabledFields.pincode}
                      placeholder="Postal Code"
                      autoComplete="off"
                      type="text"
                      value={formValues.pincode || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.pincode}
                    />
                    {errors.pincode && (
                      <small className="text-danger">{errors.pincode}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {/* <h4>Upload Logo</h4>
                <Row className="mt-3">
                  <Col md="4" sm="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="logoUpload"
                      >
                        Upload Organization Logo
                      </label>
                      <Input
                        value={formValues.logo || ""}
                        name="logo"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}

              <div className="d-flex justify-content-center">
                <Button
                  color={handleDisable() ? "secondary" : "info"}
                  type="button"
                  onClick={handleSubmit}
                  disabled={handleDisable()}
                >
                  Save
                </Button>
              </div>
            </>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h3 className="mb-0">Email Settings</h3>
            {/* <h3 style={styles.noteTextStyle}>
              Please note : Users on the Free Plan cannot change this setting.
              Please upgrade your plan to access this feature.
            </h3> */}
          </CardHeader>
          <CardBody>
            <FormGroup className="d-flex">
              <Label>
                Remove "Sent via BulkCalendar.com" link from calendar emails
              </Label>
              <Label className="custom-toggle custom-toggle-info ml-2">
                <Input
                  type="checkbox"
                  //Here if powerdByFlag is "YES" backend side it means the link will get send and on frontend the label is written like "remove the link" whose defination will be in like YES or NO answer means if the flag on backend is YES then on front end it'll be shown "false"
                  checked={
                    organizationDetails?.organizationDetails.poweredByFlag ===
                    "NO"
                      ? true
                      : false
                  }
                  onChange={(e) => handleCheckBox(e)}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="NO"
                  data-label-on="YES"
                ></span>
              </Label>
            </FormGroup>
          </CardBody>
        </Card>
        <Modal
          isOpen={handleModal}
          toggle={handleModalChange}
          className="modal-dialog-centered modal-secondary"
        >
          <CardHeader>
            <h3 className="mb--2">Upgrade Plan</h3>
          </CardHeader>

          <CardBody className="bg-white">
            <p>
              Users on the Free Plan cannot change this setting. Please upgrade
              your plan to access this feature.
            </p>
            <Button
              color={"info"}
              type="submit"
              onClick={() => {
                navigate("/a/plans");
              }}
              className="float-right"
            >
              Upgrade Plan
            </Button>
          </CardBody>
        </Modal>
      </Container>
    </>
  );
}

export default Organization;

const styles = {
  noteTextStyle: {
    marginBottom: "-15px",
    paddingTop: "20px",
    width: "100%",
    fontSize: "0.875rem",
    color: "#6c757d",
  },
};
