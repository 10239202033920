import React, { useRef, useState } from "react";

const ColumnFilterDropdown = ({
  columnsList,
  selectedMoreCols,
  setSelectedMoreCols,
  attendeeList,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown and manage outside click listener
  const toggleDropdown = (e) => {
    e.stopPropagation();

    if (!dropdownOpen) {
      // Add event listener to detect outside clicks
      document.addEventListener("click", handleClickOutside);
    } else {
      // Remove listener when dropdown is closed
      document.removeEventListener("click", handleClickOutside);
    }

    // Toggle dropdown visibility
    setDropdownOpen(!dropdownOpen);
  };

  // Detect clicks outside the dropdown to close it
  const handleClickOutside = (event) => {
    // contains() method is a DOM method that checks whether a specific element is inside another element.
    {
      /*the condition equates to that dropdown exist and the click has not happend on dropdown but happend outside of it
    so close the dropdown*/
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
      document.removeEventListener("click", handleClickOutside); // Cleanup listener
    }
  };

  // Determine icon color based on column selection
  const iconColor = selectedMoreCols.length > 0 ? "text-primary" : "text-muted";

  //on change of column selection updating the state
  const handleColumnChange = (event, column) => {
    const isSelected = event.target.checked;
    const updatedSelection = isSelected
      ? [...selectedMoreCols, column]
      : selectedMoreCols.filter((col) => col.value !== column.value);

    setSelectedMoreCols(updatedSelection);
  };

  //clear the selected columns and close dropdown
  const clearColumns = () => {
    setSelectedMoreCols([]);
    setDropdownOpen(false);
  };

  return (
    <div>
      {/* Icon that opens dropdown on click */}
      {attendeeList?.totalRecords > 0 && (
        <span
          className={`ml-2 ${iconColor} fa-lg`}
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        >
          <i className={`fa-solid fa-table-columns ${iconColor}`}></i>
        </span>
      )}

      {/* Dropdown for column selection */}
      {dropdownOpen && (
        <div
          ref={dropdownRef} // Attach ref for outside click detection
          style={styles.colDropdown}
        >
          <h5 className="mb-2">Select More Columns</h5>
          <div>
            {columnsList.map((column) => (
              <div key={column.value} className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={column.value}
                  id={`column-${column.value}`}
                  checked={selectedMoreCols.some(
                    (col) => col.value === column.value
                  )}
                  onChange={(e) => handleColumnChange(e, column)}
                />
                <label
                  className="form-check-label ml-2"
                  htmlFor={`column-${column.value}`}
                >
                  {column.label}
                </label>
              </div>
            ))}
          </div>
          {selectedMoreCols.length > 0 && (
            <p
              style={styles.clearText}
              className="mt-3 m-0 text-muted"
              onClick={() => clearColumns()}
            >
              <i className="fa-solid fa-close mr-2"></i>Clear all
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ColumnFilterDropdown;

const styles = {
  colDropdown: {
    position: "absolute",
    top: "100%",
    right: 10,
    width: "11rem",
    padding: "0.9rem",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "0.4rem",
    boxShadow: "0rem 0.4rem 0.8rem rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  },
  clearText: {
    fontWeight: "400",
    cursor: "pointer",
    fontSize: "0.9rem",
  },
};
