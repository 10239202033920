import { countryList } from "constants/SettingConstants/organization";
import { organizationDetails } from "constants/SettingConstants/organization";

const initialState = {
  organizationDetails: {},
  countryList:[]
};

const Organization = (state = initialState, action) => {
  switch (action.type) {
    case organizationDetails:
      return { ...state, organizationDetails: action.payload };

    case countryList:
      return {...state, countryList:action.payload}

    default:
      return state;
  }
};

export default Organization;
