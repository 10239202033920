import { campaignList } from "constants/CampaignConstants/campaignList";

const initialState = {
  campaignList: [],
};

const campaignListReducers = (state = initialState, action) => {
  switch (action.type) {
    case campaignList:
      return { ...state, campaignList: action.payload };

    default:
      return state;
  }
};

export default campaignListReducers;
