import { unsubscriberList } from "constants/unsubscribe/unsubscribe";

const initialState = {
  unsubscriberList: [],
};

const unsubscribe = (state = initialState, action) => {
  switch (action.type) {
    case unsubscriberList:
      return { ...state, unsubscriberList: action.payload };

    default:
      return state;
  }
};

export default unsubscribe;
