import React from "react";
// reactstrap components
import { Card, CardBody, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import NotificationAlert from "react-notification-alert";
import { useNavigate } from "react-router-dom";
import { cyanBlue } from "components/Common/constants";
import { useSelector } from "react-redux";
import { convertUTCToLocal } from "components/CommonFunctions";

function SubUsersList(props) {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const subUsers = useSelector(
    (state) => state.Dashboard.dashboardData.activeAndInvitedSubUsers
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const notificationAlertRef = React.useRef(null);

  React.useEffect(() => {
    setIsLoading(false);
  }, [subUsers]);
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Company Name" /> */}

      <Card className="mb-4" style={styles.cardStyle}>
        <CardBody>
          <div md={12} style={styles.header}>
            <h3 className="mb-0">Sub Users</h3>
            {role === "TENANT_ADMIN" && (
              <Button
                color="info"
                className="p-1"
                onClick={() => navigate("/a/sub-users")}
              >
                See all
              </Button>
            )}
          </div>
          {isLoading ? (
            <div style={styles.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={styles.loaderColor}
              />
            </div>
          ) : !subUsers ? (
            <div style={styles.loaderContainer}>
              Something went wrong please try after some time.
            </div>
          ) : subUsers?.length !== 0 ? (
            <ToolkitProvider
              data={subUsers || []}
              keyField="email"
              columns={[
                {
                  dataField: "firstName",
                  text: "Name",
                  formatter: (cell) => {
                    return cell ? cell : "NA";
                  },
                },
                {
                  dataField: "email",
                  text: "Email",
                },
                {
                  dataField: "createdOn",
                  text:
                    //   row.status === "INVITED_SUB_USER"
                    //     ? "Invited At"
                    //     :
                    "Date",
                  formatter: (cell) => {
                    return convertUTCToLocal(cell); //to show the date in users local time
                  },
                },
                {
                  dataField: "status",
                  text: "Status",
                  // formatter: statusCellRenderer,
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive ">
                  <div className="d-flex justify-content-center mr-9"></div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    // pagination={pagination}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : (
            <div style={styles.loaderContainer}>
              No sub users {subUsers?.data?.User ? "added" : "invited"} yet.
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default SubUsersList;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "35vh",
  },
  loaderColor: { color: cyanBlue },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardStyle: { height: "25rem", overflow: "hidden" },
};
