import { authorizedUser } from "components/CommonFunctions";
import * as api from "../../API/index";
import { unsubscriberList } from "constants/unsubscribe/unsubscribe";

export const getUnsubscriberList = async (dispatch, pageNo) => {
  try {
    const res = await api.unsubscriber_list(pageNo);
    dispatch({ type: unsubscriberList, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: unsubscriberList, payload: error.response.data });
  }
};

export const getUserInfoForUnsubscribing = async (uuid) => {
  try {
    const res = await api.get_user_info(uuid);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
export const unsubscribingUser = async (uuid, bodyData) => {
  try {
    const res = await api.unsubscribeUser(uuid, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
