import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Label,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_LOGIN } from "API/apiConstants";

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { forgetPassword } from "action/Settings/SubUser";
import { GOOGLE_LOGIN } from "API/apiConstants";
import { Backdrop, Divider } from "@mui/material";
import ServerIndicator from "components/Common/ServerIndicator";

function Login() {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [forgetPass, setForgetPass] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))?.token
    : null;
  const notificationAlertRef = React.useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current?.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
      // navigate("/auth/login", {
      //   state: null,
      // });
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    if (location.state?.msg) notify("success", location.state?.msg);
  }, [location.state?.msg]);
  // Function to extract query parameters from URL
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    if (error) {
      notify("error", error);
    }
  }, []);

  React.useEffect(() => {
    if (token) navigate("/a/dashboard");
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateForm(name, value);
  };

  const validateForm = (name, value) => {
    let isValid = true;

    // Handle field-specific validation
    switch (name) {
      case "email":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "This field is required",
          }));
          isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Please enter a valid email address",
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        }
        break;

      case "password":
        if (!forgetPass) {
          // Only validate password if forgetPass is false
          if (!value) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              password: "This field is required",
            }));
            isValid = false;
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              password: "",
            }));
          }
        }
        break;

      default:
        // Handle any other fields if needed
        break;
    }

    return isValid;
  };

  const resetPassword = async () => {
    const res = await forgetPassword({ email: formData.email });
    if (res.code === 200) {
      notify("success", res.message);
      setForgetPass(false);
    } else {
      notify("error", res.message);
    }
  };

  const handleSubmit = () => {
    if (!forgetPass) {
      if (validateForm()) {
        setIsLoading(true);
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        fetch(USER_LOGIN, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(formData),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.code === 200) {
              localStorage.setItem("p", JSON.stringify(res.data));
              setIsLoading(false);
              navigate("/a/dashboard");
            } else {
              setIsLoading(false);
              if (Array.isArray(res.message))
                notify("error", res.message[0].Msg);
              else notify("error", res.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            notify(
              "error",
              "Something went wrong try again later or login with credential."
            );
          });
      } else {
        notify("error", "Please fill correct data.");
        setIsLoading(false);
      }
    } else {
      resetPassword();
    }
  };

  const handleGoogleLogin = () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(`${GOOGLE_LOGIN}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          window.location.href = res.data;
        } else {
          notify(
            "error",
            res.message || "An error occurred during Google login."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Something went wrong try again later or login with credential.");
      });
  };

  const hasErrorsOrEmptyFields = () => {
    if (!forgetPass) {
      const isEmpty = Object.values(formData).some(
        (field) => field.trim() === ""
      );
      const hasErrors = Object.values(errors).some((error) => error !== "");
      return isEmpty || hasErrors;
    } else {
      // Validate email if forgetPass is true
      const email = formData.email;

      // Check if email is present and valid
      const emailIsEmpty = !email;
      const emailIsInvalid = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

      // No other fields to check
      return emailIsEmpty || emailIsInvalid;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={isLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>

      <>
        <div className="rna-wrapper1">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <AuthHeader />
        <Container className="mt--7 pb-8">
          <Row className="justify-content-center mb-5">
            <Col lg="6" md="6" xs="12" sm="12">
              <Card className="bg-secondary border-0 mb-0" xs={12} sm={12}>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col md="10" className="pr-md-2">
                      <h2
                        className="text-#0da5c0 text-center mt-2 mb-3"
                        style={styles.heading}
                      >
                        {!forgetPass ? "Sign in" : "Forgot password"}
                      </h2>
                      <div className="btn-wrapper text-center mb-3 ">
                        {!forgetPass && (
                          <Button
                            className=" btn-icon text-white"
                            style={styles.googleButton}
                            onClick={handleGoogleLogin}
                          >
                            <span className="btn-inner--icon bg-white p-2 ml--3 ">
                              <img
                                alt="Google Icon"
                                src={
                                  require("assets/img/icons/common/google.svg")
                                    .default
                                }
                                // This style makes the icon white
                              />
                            </span>
                            <span className="btn-inner--text">
                              Sign in with Google
                            </span>
                          </Button>
                        )}
                      </div>
                      {!forgetPass ? <Divider>or</Divider> : <Divider />}
                      <div
                        className="text-center text-muted mb-3 mt-3"
                        style={styles.heading2}
                      >
                        {!forgetPass ? (
                          ""
                        ) : (
                          <span>Enter your Email to verify</span>
                        )}
                      </div>
                      <Form role="form" onKeyPress={handleKeyPress}>
                        <FormGroup>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              autoComplete="off"
                              name="email"
                              onChange={handleChange}
                              // onFocus={() => setFocusedEmail(true)}
                              // onBlur={() => setFocusedEmail(false)}
                              error={errors.email}
                            />
                          </InputGroup>
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </FormGroup>

                        {!forgetPass && (
                          <FormGroup>
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                value={formData.password}
                                placeholder="Password"
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                // onFocus={() => setFocusedPassword(true)}
                                // onBlur={() => setFocusedPassword(false)}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i
                                    className={`fa ${
                                      passwordVisible
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                    aria-hidden="true"
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: "pointer" }}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {errors.password && (
                              <small className="text-danger">
                                {errors.password}
                              </small>
                            )}
                            <div className="custom-control custom-control-alternative custom-checkbox mt-2">
                              <input
                                className="custom-control-input"
                                id="customCheckLogin"
                                type="checkbox"
                              />
                            </div>
                            <div className="text-center mt--1">
                              <Label className="text-center">
                                Don't have an Account?
                                <br />
                                <a
                                  style={styles.forgetPass}
                                  className="text-[#0da5c0]"
                                  onClick={() => navigate("/auth/register")}
                                >
                                  {" "}
                                  Sign Up - Forever Free Account
                                </a>
                              </Label>
                            </div>
                          </FormGroup>
                        )}

                        <div className="text-center">
                          <Button
                            className={`my-4 ${!forgetPass ? "mt--3" : ""} `}
                            style={styles.buttonStyle}
                            type="button"
                            onClick={handleSubmit}
                            disabled={hasErrorsOrEmptyFields()}
                          >
                            {!forgetPass ? "Sign in" : "Send Recovery Email"}
                          </Button>
                        </div>
                        <div className="text-center">
                          <a
                            className="text-blue"
                            style={styles.forgetPass}
                            onClick={() => setForgetPass(!forgetPass)}
                          >
                            <span>
                              {!forgetPass ? "Forgot Password?" : "Back"}
                            </span>
                          </a>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ServerIndicator />
      </>
    </>
  );
}

export default Login;
const styles = {
  forgetPass: {
    cursor: "pointer",
    fontSize: "1rem",
  },
  heading2: { fontSize: "1.2rem", fontWeight: "bold" },
  heading: { color: "#0da5c0", fontSize: "1.5rem" },
  googleButton: {
    backgroundColor: "#00639b",
    paddingBottom: "0.7rem",
    paddingTop: "0.8rem",
  },
  buttonStyle: {
    backgroundColor: "#0da5c0",
    color: "white",
  },
};
