import { timeZone } from "constants/SettingConstants/profile";
import { profileDetails } from "constants/SettingConstants/profile";


const initialState = {
  profileDetails: {},
  timeZone:[]
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case profileDetails:
      return { ...state, profileDetails: action.payload };

      case timeZone:
        return { ...state, timeZone: action.payload };

    default:
      return state;
  }
};

export default Profile;
