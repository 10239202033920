import { campaignList } from "constants/CampaignConstants/campaignList";
import * as api from "../../API/index";
import { authorizedUser } from "components/CommonFunctions";

export const getCampaignList = async (
  dispatch,
  pageNo,
  sortParam,
  filterParams
) => {
  try {
    const res = await api.get_campaign_list(pageNo, sortParam, filterParams);
    dispatch({ type: campaignList, payload: res.data.data });
  } catch (error) {
    dispatch({ type: campaignList, payload: error });
    authorizedUser(error.response?.data);
  }
};

export const deleteCampaign = async (ID) => {
  try {
    const res = await api.delete_campaign(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const getInviteCount = async (campaignCode) => {
  try {
    const res = await api.get_invite_count(campaignCode);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
