import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import {
  getAttendeeList,
  getCampaignDetails,
} from "action/campaign/createCampaign";

import {
  updateAttendee,
  addAttendee,
  sendTestInviteEmail,
} from "action/campaign/createCampaign";
import { useDispatch } from "react-redux";
function AddOrEditAttendeeModal({
  openModal,
  toggleModalState,
  modalState,
  setModalState,
  campaignCode,
  notify,
  attendeeListRecords,
  usedDynamicParams,
  pageNum,
  setPageNum,
  filterParams,
  sortParam,
}) {
  const dispatch = useDispatch();
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);

  const mapData = [
    {
      label: "Attendee Email",
      maxLength: 100,
      name: "email",
      errMsg: "Please enter a valid email address.",
    },
    {
      label: "First Name",
      maxLength: 50,
      errMsg: "First name should contain only alphabets and cannot be empty.",
      name: "firstName",
    },
    {
      label: "Last Name",
      maxLength: 50,
      errMsg: "Last name should contain only alphabets and cannot be empty.",
      name: "lastName",
    },
    {
      label: "Field1",
      maxLength: 255,
      name: "field1",
      errMsg: "Field1 cannot be empty. Please provide a valid input.",
    },
    {
      label: "Field2",
      maxLength: 255,
      name: "field2",
      errMsg: "Field2 cannot be empty. Please provide a valid input.",
    },
    {
      label: "Field3",
      maxLength: 255,
      name: "field3",
      errMsg: "Field3 cannot be empty. Please provide a valid input.",
    },
    {
      label: "Field4",
      maxLength: 255,
      name: "field4",
      errMsg: "Field4 cannot be empty. Please provide a valid input.",
    },
    {
      label: "Field5",
      maxLength: 255,
      name: "field5",
      errMsg: "Field5 cannot be empty. Please provide a valid input.",
    },
  ];
  //on change of the fields of the modal fields
  const handleChangeSendEmail = (name, value) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        emailSendFields: {
          ...prevState.emailSendFields,
          [name]: { value: value, isValid: true },
        },
      };
    });
  };

  //validation of the modal fields
  const onBlurSendEmail = (name, value) => {
    if (name === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      setModalState((prevState) => {
        return {
          ...prevState,
          emailSendFields: {
            ...prevState.emailSendFields,
            [name]: { ...prevState.emailSendFields[name], isValid: false },
          },
        };
      });
    } else if (value.trim().length === 0) {
      setModalState((prevState) => {
        return {
          ...prevState,
          emailSendFields: {
            ...prevState.emailSendFields,
            [name]: { ...prevState.emailSendFields[name], isValid: false },
          },
        };
      });
    } else if (
      (name === "firstName" || name === "lastName") &&
      !/^[a-zA-Z ]*$/.test(value)
    ) {
      setModalState((prevState) => {
        return {
          ...prevState,
          emailSendFields: {
            ...prevState.emailSendFields,
            [name]: { ...prevState.emailSendFields[name], isValid: false },
          },
        };
      });
    } else {
      setModalState((prevState) => {
        return {
          ...prevState,
          emailSendFields: {
            ...prevState.emailSendFields,
            [name]: { ...prevState.emailSendFields[name], value: value.trim() },
          },
        };
      });
    }
  };

  //send test calendar invite
  const handleSendTestEmail = async () => {
    const bodyData = {
      email: modalState.emailSendFields.email.value,
      firstName: modalState.emailSendFields.firstName.value,
      lastName: modalState.emailSendFields.lastName.value,
      field1: modalState.emailSendFields.field1.value,
      field2: modalState.emailSendFields.field2.value,
      field3: modalState.emailSendFields.field3.value,
      field4: modalState.emailSendFields.field4.value,
      field5: modalState.emailSendFields.field5.value,
    };
    const res = await sendTestInviteEmail(campaignCode, bodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Test Email Sent Successfully",
        subMessage: "",
      });
      getCampaignDetails(dispatch, campaignCode);
      toggleModalState(modalState.mode);
    } else {
      notify("error", {
        message: "Email Sent failed!",
        subMessage: res.message || "Something went wrong.",
      });
    }
    setShowLoaderOnClick(false);
  };

  const getChangedBodyData = () => {
    const oldAttendeeFields = attendeeListRecords.find((item) => {
      return item.ID === modalState.emailSendFields.ID;
    }); // get the old attendee fields by it ID
    let changedFields = { ID: modalState.emailSendFields.ID };
    for (const key in modalState.emailSendFields) {
      if (
        typeof modalState.emailSendFields[key] === "object" &&
        modalState.emailSendFields[key]?.value.trim() !==
          oldAttendeeFields[key].trim()
      ) {
        //If input is empty then addes 1 space to it so that value will update as blank
        let changeValue =
          modalState.emailSendFields[key].value !== ""
            ? modalState.emailSendFields[key].value
            : " ";
        changedFields = {
          ...changedFields,
          [key]: changeValue,
        };
      }
    }
    return changedFields;
  };

  //on click of update for attendee
  const handleAttendeeUpdate = async () => {
    const bodyData = getChangedBodyData();
    const res = await updateAttendee(campaignCode, bodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Attendee record updated successfully",
        subMessage: "",
      });
      // called attendee list with applied params of search or sort  abd page if any
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum, // got via props
        filterParams, //got via props
        sortParam //got via props
      );
      getCampaignDetails(dispatch, campaignCode);
      toggleModalState(modalState.mode);
    } else {
      notify("error", {
        message: "Attendee record updated failed!",
        subMessage: res.message || "Something went wrong.",
      });
    }
    setShowLoaderOnClick(false);
  };
  //on click of add for attendee
  const handleAttendeeAdd = async () => {
    const bodyData = {
      email: modalState.emailSendFields.email.value,
      firstName: modalState.emailSendFields.firstName.value,
      lastName: modalState.emailSendFields.lastName.value,
      field1: modalState.emailSendFields.field1.value,
      field2: modalState.emailSendFields.field2.value,
      field3: modalState.emailSendFields.field3.value,
      field4: modalState.emailSendFields.field4.value,
      field5: modalState.emailSendFields.field5.value,
    };
    const res = await addAttendee(campaignCode, bodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Attendee record added successfully",
        subMessage: "",
      });
      getCampaignDetails(dispatch, campaignCode);
      if (pageNum === 1) {
        // if it is on page one only then called attendee list with applied params of search or sort if any
        getAttendeeList(
          dispatch,
          campaignCode,
          pageNum,
          filterParams, //got via props
          sortParam //got via props
        );
      } else {
        //on add single attendee to make sure user able to see the records, therefore set page 1
        setPageNum(1);
      }
      toggleModalState(modalState.mode);
    } else {
      notify("error", {
        message: "Attendee record creation failed!",
        subMessage: res.message || "Something went wrong.",
      });
    }
    setShowLoaderOnClick(false);
  };

  const checkIfAnyFieldChanged = () => {
    const oldAttendeeFields = attendeeListRecords.find(
      (item) => item.ID === modalState.emailSendFields.ID
    );

    // If no old attendee is found, return false (no change detected)
    if (!oldAttendeeFields) {
      return false;
    }

    // Iterate through all the fields
    return Object.keys(modalState.emailSendFields).some((key) => {
      const newField = modalState.emailSendFields[key];
      const oldField = oldAttendeeFields[key];

      // Check if it's an object with a value
      if (typeof newField === "object" && newField?.value !== undefined) {
        const trimmedNewValue = newField.value.trim();
        const trimmedOldValue = oldField?.trim();

        // If any field is updated to an empty value, return false immediately
        if (trimmedNewValue === "") {
          return false;
        }

        // Return true if any field has changed
        return trimmedNewValue !== trimmedOldValue;
      }

      // For primitive values, check if it's set to an empty value
      if (newField === "" || newField === undefined) {
        return false;
      }

      // Return true if any primitive field has changed
      return newField !== oldField;
    });
  };

  //To show the input fields dynamically
  const handleShowInputFields = ({ label, field, maxLength, errMsg }) => {
    return (
      field !== "email" &&
      usedDynamicParams?.[field] && (
        <Col md={6} key={field}>
          <FormGroup>
            <label className="form-control-label">
              {label}
              <strong style={{ color: "red" }}> *</strong>
            </label>
            <Input
              placeholder={label}
              type="text"
              name={field}
              maxLength={maxLength}
              value={modalState?.emailSendFields?.[field].value}
              onChange={(e) =>
                handleChangeSendEmail(e.target.name, e.target.value)
              }
              onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
              //If "?" not included after dynamic key it shows an error
              className={
                modalState.emailSendFields?.[field].isValid ? "" : "is-invalid"
              }
              autoComplete="off"
            />

            {!modalState.emailSendFields?.[field].isValid && (
              <div className="invalid-feedback">{errMsg}</div>
            )}
          </FormGroup>
        </Col>
      )
    );
  };

  //To check if has any invalid fields to make button disable and enable
  function hasInvalidFields() {
    for (let key in usedDynamicParams) {
      if (
        usedDynamicParams[key] &&
        (!modalState.emailSendFields[key].isValid ||
          modalState.emailSendFields[key].value === "")
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    <Modal isOpen={openModal} size="lg">
      <ModalHeader
        toggle={() => {
          toggleModalState(modalState.mode);
        }}
      >
        {modalState.mode === "test"
          ? "Send Test Calendar Invite"
          : modalState.mode === "edit"
          ? "Edit Attendee Record"
          : "Add Attendee"}
        {modalState.mode === "test" && (
          <p style={{ fontSize: "0.9rem" }} className="mt-2">
            1 credit will be used to send a test invite.
          </p>
        )}
      </ModalHeader>
      <ModalBody className="pt-0">
        <Row lg="12" md="12" sm="12">
          <Col md={6}>
            <FormGroup>
              <label className="form-control-label">
                Attendee Email
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <Input
                placeholder={"Attendee Email"}
                type="text"
                name={"email"}
                maxLength={100}
                value={modalState.emailSendFields.email.value}
                onChange={(e) =>
                  handleChangeSendEmail(e.target.name, e.target.value)
                }
                onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
                className={
                  modalState.emailSendFields.email.isValid ? "" : "is-invalid"
                }
                autoComplete="off"
              />

              {!modalState.emailSendFields.email.isValid && (
                <div className="invalid-feedback">
                  Please enter a valid email.
                </div>
              )}
            </FormGroup>
          </Col>
          {mapData.map((item) => {
            return handleShowInputFields({
              label: item.label,
              field: item.name,
              maxLength: item.maxLength,
              errMsg: item.errMsg,
            });
          })}
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="info"
              className="mt-1"
              onClick={() => {
                setShowLoaderOnClick(true);
                if (modalState.mode === "test") {
                  handleSendTestEmail();
                } else if (modalState.mode === "edit") {
                  handleAttendeeUpdate();
                } else if (modalState.mode === "add") {
                  handleAttendeeAdd();
                }
              }}
              size="md"
              disabled={
                (modalState.mode === "edit"
                  ? !checkIfAnyFieldChanged()
                  : hasInvalidFields()) || showLoaderOnClick
              }
            >
              {showLoaderOnClick ? (
                <i className="fa fa-spinner fa-spin fa-lg text-white" />
              ) : modalState.mode === "test" ? (
                "Send Test Invite"
              ) : modalState.mode === "edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default AddOrEditAttendeeModal;
