import { subUsersList } from "constants/SettingConstants/subUser";
import * as api from "../../API/index";
import { authorizedUser } from "components/CommonFunctions";

export const inviteSubUser = async (inviteData) => {
  try {
    const res = await api.invite_sub_user(inviteData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const forgetPassword = async (email) => {
  try {
    const res = await api.forget_password(email);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const resetPassword = async (data) => {
  try {
    const res = await api.reset_password(data);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const subUserList = async (dispatch) => {
  try {
    const res = await api.sub_user_list();
    dispatch({ type: subUsersList, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: subUsersList, payload: error.response.data });
  }
};

export const invitedSubUserList = async (dispatch) => {
  try {
    const res = await api.invited_sub_user_list();
    dispatch({ type: subUsersList, payload: res.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: subUsersList, payload: error.response.data });
  }
};

export const deleteSubUser = async (ID) => {
  try {
    const res = await api.delete_sub_user(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const deleteInvitedSubUser = async (ID) => {
  try {
    const res = await api.delete_invited_sub_user(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
