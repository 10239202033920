import React from "react";
import { Button, Card, CardBody, Row, Col, Container, Label } from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useLocation, useNavigate } from "react-router-dom";

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import RegistrationDetails from "./RegistrationDetails";
import { GOOGLE_SIGN_UP } from "API/apiConstants";
import { Backdrop, Divider } from "@mui/material";
import ServerIndicator from "components/Common/ServerIndicator";

function Registration() {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();
  const token = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))?.token
    : null;
  const notificationAlertRef = React.useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };

    notificationAlertRef.current?.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 3000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    if (location.state?.msg) notify("success", location.state?.msg);
  }, [location.state?.msg]);

  // Function to extract query parameters from URL
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const name = searchParams.get("name");
    const role = searchParams.get("role");
    const error = searchParams.get("error");
    if (token && name && role) {
      navigate("/a/dashboard");
      const p = {
        token: token,
        name: name,
        role: role,
      };
      localStorage.setItem("p", JSON.stringify(p));
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } else if (error) {
      notify("error", error);
    }
  }, []);
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  React.useEffect(() => {
    if (token) navigate("/a/dashboard");
  }, [token]);

  const handleGoogleSignup = () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(`${GOOGLE_SIGN_UP}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          setIsLoading(false);
          window.location.href = res.data;
        } else {
          setIsLoading(false);
          notify(
            "error",
            res.message || "An error occurred during Google registration."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert(
          "Something went wrong try again later or register with credential."
        );
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={isLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>

      <>
        <div className="rna-wrapper1">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <AuthHeader />
        <Container className="mt--7 pb-8">
          <Card
            className="bg-secondary border-0 mb-0"
            xs={12}
            sm={12}
            style={{
              background:
                windowWidth <= 768
                  ? "linear-gradient(180deg, #f7fafc 50%, #eeeeee 50%)"
                  : "linear-gradient(90deg, #eeeeee 50%, #f7fafc 50%)",
            }}
          >
            <CardBody className="px-lg-1 py-lg-1">
              <Row className="d-flex justify-content-center mb-1">
                {windowWidth <= 768 && (
                  <>
                    <Col md={5} className="pl-md-2 p-2">
                      <h2
                        className="text-center mt-2 mb-3 "
                        style={styles.heading}
                      >
                        Register-Forever Free Account
                      </h2>
                      <div className="btn-wrapper text-center mb-3">
                        <Button
                          style={styles.googleButton}
                          className=" btn-icon text-white"
                          // color="blue"
                          onClick={handleGoogleSignup}
                        >
                          <span className="btn-inner--icon bg-white p-2 ml--3">
                            <img
                              alt="Google Icon"
                              src={
                                require("assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">
                            Sign up with Google
                          </span>
                        </Button>
                      </div>
                      <Divider>or</Divider>
                      <div
                        className="text-center text-muted mb-3 mt-3"
                        style={styles.heading2}
                      ></div>
                      <RegistrationDetails setIsLoading={setIsLoading} />
                    </Col>
                  </>
                )}
                <>
                  <Col md={5}>
                    <h2
                      className="text-#0da5c0 text-left mt-5 mb-3"
                      style={styles.heading3}
                    >
                      Forever Free Account
                    </h2>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        500 Calendar Invites/month
                      </Label>
                    </div>

                    {/* <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">2 Campaigns </Label>
                    </div> */}
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        Personalized Calendar, Emails
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        Upload Bulk Attendees
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">Send in 1 Click</Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        No Credit card Required
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        Initial on call Support
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">
                        Ticket based support
                      </Label>
                    </div>
                  </Col>

                  {/* Vertical line */}
                  <Col
                    md={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div style={styles.verticalLine} />
                  </Col>
                  {windowWidth >= 768 && (
                    <Col md={5} className="pl-md-2 p-2">
                      <h2
                        className="text-center mt-2 mb-3 "
                        style={styles.heading}
                      >
                        Register-Forever Free Account
                      </h2>
                      <div className="btn-wrapper text-center mb-3">
                        <Button
                          style={styles.googleButton}
                          className=" btn-icon text-white"
                          // color="blue"
                          onClick={handleGoogleSignup}
                        >
                          <span className="btn-inner--icon bg-white p-2 ml--3">
                            <img
                              alt="Google Icon"
                              src={
                                require("assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">
                            Sign up with Google
                          </span>
                        </Button>
                      </div>
                      <Divider>or</Divider>
                      <div
                        className="text-center text-muted mb-3 mt-3"
                        style={styles.heading2}
                      ></div>
                      <RegistrationDetails setIsLoading={setIsLoading} />
                    </Col>
                  )}
                </>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <ServerIndicator />
      </>
    </>
  );
}

export default Registration;
const styles = {
  forgetPass: {
    cursor: "pointer",
    fontSize: "1rem",
  },
  heading2: { fontSize: "1.2rem", fontWeight: "bold" },
  verticalLine: {
    borderLeft: "1px solid #dcdcdc" /* Adjust color to match your theme */,
    height: "100%",
    width: "1px",
  },
  heading: { color: "#0da5c0", fontSize: "1.5rem" },
  heading3: { color: "#0da5c0", fontSize: "2rem" },
  googleButton: {
    backgroundColor: "#00639b",
    paddingBottom: "0.7rem",
    paddingTop: "0.8rem",
  },
  divStyle: { display: "flex", alignItems: "center" },
  iconStyle: { marginRight: "8px" },
};
