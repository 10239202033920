import {
  mailingServiceList,
  emailConfigList,
} from "constants/SettingConstants/emailConfig";
import * as api from "../../API/index";
import { authorizedUser } from "components/CommonFunctions";

export const getMailingServiceList = async (dispatch) => {
  try {
    const res = await api.get_mailing_service_list();
    dispatch({ type: mailingServiceList, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    console.log(error);
  }
};

export const addEmailConfig = async (bodyData) => {
  try {
    const res = await api.add_email_configuration(bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const listEmailConfig = async (dispatch, pageNo) => {
  try {
    const res = await api.get_email_config_list(pageNo);
    dispatch({ type: emailConfigList, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: emailConfigList, payload: error.response.data });
  }
};

export const deleteEmailConfigu = async (ID) => {
  try {
    const res = await api.delete_email_config(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const getEmailConfig = async (ID) => {
  try {
    const res = await api.get_email_config(ID);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const updateEmailConfig = async (ID, bodyData) => {
  try {
    const res = await api.update_email_config(ID, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};
