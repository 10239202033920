import {
  mailingServiceList,
  emailConfigList,
} from "constants/SettingConstants/emailConfig";

const initialState = {
  mailingServiceList: [],
  emailConfigList: [],
};

const EmailConfigReducers = (state = initialState, action) => {
  switch (action.type) {
    case mailingServiceList:
      return { ...state, mailingServiceList: action.payload };

    case emailConfigList:
      return { ...state, emailConfigList: action.payload };

    default:
      return state;
  }
};

export default EmailConfigReducers;
